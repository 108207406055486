import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { createGlobalStyle } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import Routes from "../../routes";
import { loadUserData } from "../../actions/user";

const GlobalStyles = createGlobalStyle`
    html, body {
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-family: "Trebuchet MS",Tahoma,Arial,Helvetica,sans-serif;
        background-color: rgb(255, 255, 255) !important;
    }

    @font-face {
        font-family: "CMU Latex";
        src: url("/static/fonts/cmunci.ttf");
        font-style: oblique;
    }

    /* Шрифт тегов в формулах. */
    span.keyword {
        font-family: "CMU Latex";
        font-size: 1.1em;
    }

    #react-root {
        width: 100%;
        height: 100%;
    }

    .phrase__root {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
`;

const App = ({ dispatchLoadUserData }) => {
    React.useEffect(() => {
        dispatchLoadUserData();
    }, [dispatchLoadUserData]);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <CssBaseline />
            <GlobalStyles />
            <Router>
                <Routes />
            </Router>
        </MuiPickersUtilsProvider>
    );
};

App.propTypes = {
    dispatchLoadUserData: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
    dispatchLoadUserData: () => dispatch(loadUserData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
