import * as React from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles(() => ({
    static: {
        display: "flex",
        height: "122px",
        alignItems: "center",
        justifyContent: "center",
    },
    absolute: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 4,
        backgroundColor: (props) => (props.filled ? "rgba(0, 0, 0, 0.2)" : ""),
    },
    grow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        flex: 1,
    },
}));
const Loader = (props) => {
    const { loading = true, absolute, grow } = props;
    const s = useStyles(props);
    if (!loading) {
        return null;
    }
    if (grow) {
        return (React.createElement("div", { className: s.grow },
            React.createElement(CircularProgress, { size: 50 })));
    }
    return (React.createElement("div", { className: absolute ? s.absolute : s.static },
        React.createElement(CircularProgress, { size: 50 })));
};
export default Loader;
