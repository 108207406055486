const initialState = {
    open: false,
    message: "",
    type: "default",
    anchor: {
        vertical: "bottom",
        horizontal: "right",
    },
};
const snackbar = (state = initialState, action) => {
    switch (action.type) {
        case "SHOW_SNACKBAR" /* SHOW */:
            return {
                ...state,
                open: true,
                ...action.payload,
            };
        case "HIDE_SNACKBAR_CONTENT" /* HIDE_SNACKBAR_CONTENT */:
            return {
                ...state,
                open: false,
            };
        case "HIDE_SNACKBAR" /* HIDE */:
            return initialState;
        default:
            return state;
    }
};
export default snackbar;
