/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
import * as React from "react";
const routes = [
    {
        path: "/",
        component: React.lazy(() => import("client/components/Home")),
        exact: true,
        layoutProps: {
            title: "Nomotex",
            withoutOffset: true,
            fullWidth: true,
        },
    },
    {
        path: "/knowledge-base/",
        component: React.lazy(() => import("client/components/KnowledgeBase")),
        layoutProps: {
            title: "База знаний (Дерево)",
            fullWidth: true,
            withoutOffsetOnMobile: true,
        },
    },
    {
        path: "/circle-knowledge-base/",
        component: React.lazy(() => import("client/components/CircleKnowledgeBase")),
        layoutProps: {
            title: "База знаний (Круг)",
            fullWidth: true,
            withoutOffsetOnMobile: true,
        },
    },
    {
        path: "/educational-direction/tree/",
        component: React.lazy(() => import("client/components/GeneralEducationalProgramTree")),
        layoutProps: {
            title: "База нейросетевых ОП",
            fullWidth: true,
            withoutOffsetOnMobile: true,
        },
    },
    {
        path: "/submodule/:paragraphId/",
        layoutProps: {
            fullWidth: true,
        },
        analyticProps: {
            manual: true,
        },
        component: React.lazy(() => import("client/components/Paragraph/Page")),
    },
    {
        path: "/element/:elementId/",
        analyticProps: {
            manual: true,
        },
        component: React.lazy(() => import("client/components/Element/Page")),
    },
    {
        path: "/educational-direction/gep/:standardCipher?/:coursePk?",
        layoutProps: {
            fullWidth: true,
            withoutOffsetOnMobile: true,
        },
        analyticProps: {
            manual: true,
        },
        component: React.lazy(() => import("client/components/GeneralEducationalProgram")),
    },
    {
        path: "/educational-direction/add/",
        component: React.lazy(() => import("client/components/AddEducationalDirection")),
        layoutProps: {
            title: "Создать курс",
        },
    },
    {
        path: "/thesaurus/",
        component: React.lazy(() => import("client/components/Thesaurus")),
        layoutProps: {
            title: "Тезаурус",
        },
    },
    {
        path: "/visualization/:discipline?/:section?/:exampleId?",
        analyticProps: {
            manual: true,
        },
        component: React.lazy(() => import("client/components/Visualization")),
    },
    {
        path: "/module-results/:teachingId/",
        component: React.lazy(() => import("client/components/ModuleResults")),
        layoutProps: {
            title: "Рейтинг",
            fullWidth: true,
        },
    },
    {
        path: "/profile/",
        exact: true,
        component: React.lazy(() => import("client/components/AcademicPerformance")),
        layoutProps: {
            title: "Успеваемость",
        },
    },
    {
        path: "/profile/completed-tasks/",
        component: React.lazy(() => import("client/components/ControlsResults/Page")),
        layoutProps: {
            title: "Мои выполненные задания",
        },
    },
    {
        path: "/profile/notifications/",
        exact: true,
        component: React.lazy(() => import("client/components/GroupEvents")),
        layoutProps: {
            title: "Объявления",
        },
    },
    {
        path: "/profile/conferences/",
        exact: true,
        component: React.lazy(() => import("client/components/Conferences")),
        layoutProps: {
            title: "Конференции",
        },
    },
    {
        path: "/profile/common/",
        component: React.lazy(() => import("client/components/UserData")),
        layoutProps: {
            title: "Личный кабинет",
        },
    },
    {
        path: "/profile/tickets/",
        component: React.lazy(() => import("client/components/TicketsPage")),
        layoutProps: {
            title: "Билеты",
        },
    },
    {
        path: "/profile/proctoring/",
        component: React.lazy(() => import("client/components/Proctoring")),
        layoutProps: {
            title: "Прокторинг",
        },
    },
    {
        path: "/profile/proctoring-room/:roomId/",
        component: React.lazy(() => import("client/components/ProctoringRoom")),
        layoutProps: {
            title: "Прокторинг",
            empty: true,
            withoutOffset: true,
            withoutOffsetOnMobile: true,
            fullWidth: true,
        },
    },
    {
        path: "/confrences/home/:id/",
        component: React.lazy(() => import("client/components/JoinHomeConfrence")),
        layoutProps: {
            title: "Прокторинг",
            withoutOffset: true,
            withoutOffsetOnMobile: true,
            fullWidth: true,
        },
    },
    {
        path: "/profile/proctoring-end/",
        component: React.lazy(() => import("client/components/ProctoringEnd")),
        layoutProps: {
            title: "Контрольное мероприятие",
            empty: true,
            withoutOffset: true,
            withoutOffsetOnMobile: true,
            fullWidth: true,
        },
    },
    {
        path: "/faq/proctoring/",
        component: React.lazy(() => import("client/components/FAQ/Proctoring")),
        layoutProps: {
            title: "Инструкция по использованию системы Nomotex.Proctor",
        },
    },
    {
        path: "/faq/google-authenticator/",
        component: React.lazy(() => import("client/components/FAQ/GoogleAuthenticator")),
        layoutProps: {
            title: "Авторизация с помощью Google Authenticator",
        },
    },
    {
        path: "/faq/support/",
        component: React.lazy(() => import("client/components/FAQ/Support")),
        layoutProps: {
            title: "Правила обращения в техподдержку",
        },
    },
    {
        path: "/faq/quanta-formulas/",
        component: React.lazy(() => import("client/components/FAQ/QuantaFormulas")),
        layoutProps: {
            title: "Инструкция по написанию формул",
        },
    },
    {
        path: "/faq/examus/",
        component: React.lazy(() => import("client/components/FAQ/Examus")),
        layoutProps: {
            title: "Инструкция по использованию системы Examus",
        },
    },
    {
        path: "/faq/",
        component: React.lazy(() => import("client/components/FAQ")),
        layoutProps: {
            title: "Инструкции",
        },
        exact: true,
    },
    {
        path: "/profile/add-student/",
        component: React.lazy(() => import("client/components/AddStudent")),
        layoutProps: {
            title: "Добавить студента",
        },
    },
    {
        path: "/profile/tasks/",
        component: React.lazy(() => import("client/components/TasksPage")),
        layoutProps: {
            title: "Задания",
        },
    },
    {
        path: "/about/",
        component: React.lazy(() => import("client/components/About")),
        layoutProps: {
            title: "О Nomotex'e",
        },
    },
    {
        path: "/test-question/:questionId/",
        component: React.lazy(() => import("client/components/TestQuestion")),
        layoutProps: {
            title: "Тестовый вопрос",
        },
    },
    {
        path: "/examples/:exampleId/",
        component: React.lazy(() => import("client/components/ExamplePage")),
        layoutProps: {
            fullWidth: true,
        },
    },
    {
        path: "/remote-ticket/:secret?/",
        component: React.lazy(() => import("client/components/RemoteTesting/Page")),
        layoutProps: {
            title: "Контрольный билет",
            empty: true,
        },
    },
    {
        path: "/training/:eduModulePk/:secret/",
        component: React.lazy(() => import("client/components/TrainingPage")),
    },
    {
        path: "/tests/:testId/",
        component: React.lazy(() => import("client/components/TestPage")),
    },
    {
        path: "/confirm-reset-password/",
        component: React.lazy(() => import("client/components/ConfirmResetPassword")),
        layoutProps: {
            title: "Подтверждение сброса пароля",
        },
    },
];
export default routes;
