import React, { useReducer, useCallback } from "react";
import useLatest from "use-latest";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "client/features/request";
import { getCSRFToken } from "client/helpers";
import { showSnackbar as showSnackbarAction, hideSnackbar as hideSnackbarAction } from "client/actions/snackbar";
import { loadUserData } from "client/actions/user";
import { clearLectureRecordData } from "client/actions/lectureRecord";
import { RECOVERY_CATEGORY } from "client/components/Layout/constants";
const useStyles = makeStyles({
    alert: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
const initialState = {
    username: "",
    password: "",
    token: "",
    showTokenInput: false,
    tokenError: "",
    usernameError: "",
    message: "",
    reset: false,
    loading: false,
    showAuthErrorAnimation: false,
    totp: false,
};
export const useAuth = (params) => {
    const s = useStyles();
    const rdispatch = useDispatch();
    const { setShowAuthDialog } = params;
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "SET":
                return {
                    ...state,
                    ...action.payload,
                };
            case "CLEAR":
                return initialState;
            case "CLEAR_ERRORS":
                return {
                    ...state,
                    tokenError: "",
                    usernameError: "",
                };
            default:
                return state;
        }
    }, initialState);
    const latestState = useLatest(state);
    const handleFailedAttemptsError = useCallback((totp) => {
        rdispatch(showSnackbarAction({
            message: (React.createElement(Alert, { className: s.alert, severity: "error", onClose: () => rdispatch(hideSnackbarAction()) },
                "\u041F\u0440\u0435\u0432\u044B\u0448\u0435\u043D\u043E \u043A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u043D\u0435\u0432\u0435\u0440\u043D\u044B\u0445 \u043F\u043E\u043F\u044B\u0442\u043E\u043A \u0432\u0432\u043E\u0434\u0430 ",
                totp ? "токена" : "кода подтверждения")),
            type: "alert",
            autoHideDuration: 10000,
        }));
        setShowAuthDialog(false);
        dispatch({ type: "CLEAR" });
    }, [s, dispatch, rdispatch, setShowAuthDialog]);
    const handleSuccessLogin = useCallback(() => {
        gtag("event", "login", { method: "native" });
        setShowAuthDialog(false);
        dispatch({ type: "CLEAR" });
        rdispatch(loadUserData(false, "auth"));
        rdispatch(clearLectureRecordData());
    }, [setShowAuthDialog, dispatch, rdispatch]);
    const verifyToken = useCallback(async (params) => {
        var _a, _b, _c;
        const { token } = params;
        dispatch({ type: "SET", payload: { loading: true } });
        dispatch({ type: "CLEAR_ERRORS" });
        const { username, password } = state;
        try {
            const response = (await axios.post("/api/profile/login/", JSON.stringify({
                username,
                password,
                token,
            }), {
                headers: {
                    "X-CSRFToken": await getCSRFToken(),
                    "Content-Type": "application/json",
                },
            })).data;
            switch ((_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.type) {
                case "SUCCESS":
                    return handleSuccessLogin();
                default:
                    throw new Error("unknown");
            }
        }
        catch (err) {
            const error = err;
            if (!error || (typeof (error === null || error === void 0 ? void 0 : error.message) === "string" && (error === null || error === void 0 ? void 0 : error.message) === "unknown")) {
                return dispatch({
                    type: "SET",
                    payload: {
                        usernameError: "Не удалось авторизоваться, попробуйте обратиться в поддержку",
                        loading: false,
                    },
                });
            }
            if (typeof ((_b = error === null || error === void 0 ? void 0 : error.payload) === null || _b === void 0 ? void 0 : _b.type) === "string") {
                switch (error.payload.type) {
                    case "INVALID_CREDENTIALS":
                        return dispatch({
                            type: "SET",
                            payload: {
                                loading: false,
                                showTokenInput: false,
                                token: "",
                                usernameError: "Неверный логин или пароль",
                            },
                        });
                    case "INVALID_TOKEN":
                        return dispatch({
                            type: "SET",
                            payload: {
                                tokenError: error.payload.totp ? "Неверный токен" : "Неверный код из сообщения",
                                loading: false,
                            },
                        });
                    case "N_FAILED_ATTEMPTS":
                        return handleFailedAttemptsError((_c = error === null || error === void 0 ? void 0 : error.payload) === null || _c === void 0 ? void 0 : _c.totp);
                    default:
                        dispatch({
                            type: "SET",
                            payload: {
                                usernameError: "Не удалось авторизоваться, попробуйте обратиться в техподдержку",
                                loading: false,
                            },
                        });
                }
            }
        }
        dispatch({ type: "SET", payload: { loading: false } });
    }, [state, dispatch, handleSuccessLogin, handleFailedAttemptsError]);
    const handleAuthClick = useCallback(async (event, resendToken = false) => {
        var _a, _b, _c, _d;
        if (event) {
            event.preventDefault();
        }
        const { username, password, token, reset, showTokenInput } = latestState.current;
        if (token && showTokenInput && !resendToken) {
            return verifyToken({ token });
        }
        dispatch({ type: "SET", payload: { loading: true } });
        dispatch({ type: "CLEAR_ERRORS" });
        if (reset) {
            try {
                const { message: responseMessage } = (await axios.post("/api/profile/reset-password/", { username }, {
                    withCredentials: true,
                    headers: {
                        "X-CSRFToken": await getCSRFToken(),
                    },
                })).data;
                dispatch({
                    type: "SET",
                    payload: { message: responseMessage, loading: false },
                });
                gtag("event", "recovery_link_send_to_email", {
                    event_category: RECOVERY_CATEGORY,
                });
                return;
            }
            catch (_e) {
                dispatch({
                    type: "SET",
                    payload: {
                        usernameError: "Востановление пароля не поддерживается для данного пользователя",
                        loading: false,
                    },
                });
                gtag("event", "error_email_not_found", {
                    event_category: RECOVERY_CATEGORY,
                });
            }
            return dispatch({ type: "SET", payload: { loading: false } });
        }
        try {
            const response = (await axios.post("/api/profile/login/", JSON.stringify({
                username,
                password,
            }), {
                headers: {
                    "X-CSRFToken": await getCSRFToken(),
                    "Content-Type": "application/json",
                },
            })).data;
            switch ((_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.type) {
                case "SUCCESS":
                    return handleSuccessLogin();
                case "TOKEN_SEND":
                    if (resendToken) {
                        rdispatch(showSnackbarAction({
                            message: (React.createElement(Alert, { className: s.alert, severity: "info", onClose: () => rdispatch(hideSnackbarAction()) }, "\u041A\u043E\u0434 \u043F\u043E\u0434\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F \u0432\u0445\u043E\u0434\u0430 \u0432 Nomotex \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u043E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D")),
                            type: "alert",
                            autoHideDuration: 6000,
                        }));
                    }
                    return dispatch({
                        type: "SET",
                        payload: { loading: false, showTokenInput: true, totp: (_b = response === null || response === void 0 ? void 0 : response.payload) === null || _b === void 0 ? void 0 : _b.totp },
                    });
                default:
                    throw new Error("unknown");
            }
        }
        catch (err) {
            const error = err;
            if (!error || (typeof (error === null || error === void 0 ? void 0 : error.message) === "string" && (error === null || error === void 0 ? void 0 : error.message) === "unknown")) {
                return dispatch({
                    type: "SET",
                    payload: {
                        usernameError: "Не удалось авторизоваться, попробуйте обратиться в техподдержку",
                        loading: false,
                    },
                });
            }
            if (typeof ((_c = error === null || error === void 0 ? void 0 : error.payload) === null || _c === void 0 ? void 0 : _c.type) === "string") {
                switch (error.payload.type) {
                    case "INVALID_CREDENTIALS":
                        dispatch({
                            type: "SET",
                            payload: {
                                showAuthErrorAnimation: true,
                                loading: false,
                            },
                        });
                        setTimeout(() => dispatch({
                            type: "SET",
                            payload: {
                                showAuthErrorAnimation: false,
                            },
                        }), 1000);
                        return;
                    case "VALID_TOKEN_AVAILABLE": {
                        const errorField = showTokenInput ? "tokenError" : "usernameError";
                        return dispatch({
                            type: "SET",
                            payload: {
                                loading: false,
                                [errorField]: `${resendToken ? "Повторная отправка" : "Отправка"} кода подтверждения входа в Nomotex доступна только через ${error.payload.resendBound} сек.`,
                            },
                        });
                    }
                    case "N_FAILED_ATTEMPTS":
                        return handleFailedAttemptsError((_d = error === null || error === void 0 ? void 0 : error.payload) === null || _d === void 0 ? void 0 : _d.totp);
                    case "NO_DEVICES":
                    case "VERIFICATION_NOT_ALLOWED":
                        return dispatch({
                            type: "SET",
                            payload: {
                                usernameError: "Для данного пользователя не настроена двухфакторная аутентификация, обратитесь в техподдержку для обновления данных пользователя",
                                loading: false,
                            },
                        });
                    default:
                        return dispatch({
                            type: "SET",
                            payload: {
                                usernameError: "Не удалось авторизоваться, попробуйте обратиться в поддержку",
                                loading: false,
                            },
                        });
                }
            }
        }
        dispatch({ type: "SET", payload: { loading: true } });
    }, [s, rdispatch, latestState, verifyToken, dispatch, handleSuccessLogin, handleFailedAttemptsError]);
    return {
        state,
        dispatch,
        verifyToken,
        handleAuthClick,
    };
};
