import * as React from "react";
import ChatIcon from "@material-ui/icons/Chat";
import Fab from "../Fab";
import FeedbackForm from "./FeedbackForm";
const FeedbackAction = (props) => {
    const { className = "", getTransform } = props;
    const [showForm, setShowForm] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Fab, { onClick: () => setShowForm(true), className: className, getTransform: getTransform },
            React.createElement(ChatIcon, { fontSize: "small" })),
        React.createElement(FeedbackForm, { show: showForm, handleClose: () => setShowForm(false) })));
};
export default FeedbackAction;
