import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { head, filter, map, join } from "ramda";
import { getProfile } from "client/selectors";
import Tab from "./Tab";
const useStyles = makeStyles((theme) => ({
    root: {
        marginRight: theme.spacing(1),
    },
}));
const Name = () => {
    const s = useStyles();
    const history = useHistory();
    const profile = useSelector(getProfile);
    const displayName = useMemo(() => {
        if (!(profile === null || profile === void 0 ? void 0 : profile.lastName)) {
            return profile === null || profile === void 0 ? void 0 : profile.username;
        }
        const abbreviations = map((name) => `${head(name).toUpperCase()}.`, filter(Boolean, [profile.firstName, profile.middleName]));
        return join(" ", [profile.lastName, ...abbreviations]);
    }, [profile]);
    if (!displayName) {
        return null;
    }
    return React.createElement(Tab, { className: s.root, title: displayName, hideOnMobile: true, onClick: () => history.push("/profile/common/") });
};
export default Name;
