import { combineReducers } from "@reduxjs/toolkit";
import dialog from "client/slices/dialog";
import controlsTree from "client/slices/controlsTree";
import studyGroups from "client/slices/studyGroups";
import participantsFilters from "client/slices/participantsFilters";
import learningSegments from "client/slices/learningSegments";
import conferences from "client/slices/conferences";
import proctoringRooms from "client/slices/proctoringRooms";
import globalLoader from "client/slices/globalLoader";
import user from "./user";
import menu from "./menu";
import lectureRecord from "./lectureRecord";
import snackbar from "./snackbar";
import educationalTrainingModules from "./educationalTrainingModules";
import ticketsTree from "./ticketsTree";
const rootReducer = combineReducers({
    user,
    menu,
    lectureRecord,
    snackbar,
    educationalTrainingModules,
    ticketsTree,
    dialog,
    studyGroups,
    controlsTree,
    participantsFilters,
    learningSegments,
    conferences,
    proctoringRooms,
    globalLoader,
});
export default rootReducer;
