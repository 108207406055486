import React from "react";
import { localStorageSafeGet, localStorageSafeSet } from "client/helpers/localStorage";
import { Position } from "client/typings";
import { showSnackbar } from "./snackbar";
export const displayNotificationsOnAuth = () => async (dispatch, getState) => {
    const state = getState();
    if (state.user.profile.position === Position.Student && localStorageSafeGet("notification:student-one-login", "true") !== "true") {
        dispatch(showSnackbar({
            message: (React.createElement(React.Fragment, null,
                "\u0414\u043B\u044F \u0441\u0442\u0443\u0434\u0435\u043D\u0442\u043E\u0432 \u0440\u0430\u0437\u0440\u0435\u0448\u0430\u0435\u0442\u0441\u044F \u043E\u0434\u043D\u043E\u0432\u0440\u0435\u043C\u0435\u043D\u043D\u043E \u0437\u0430\u0445\u043E\u0434\u0438\u0442\u044C \u0432 Nomotex \u0442\u043E\u043B\u044C\u043A\u043E \u0441 \u043E\u0434\u043D\u043E\u0433\u043E \u0443\u0441\u0442\u0440\u043E\u0439\u0441\u0442\u0432\u0430",
                React.createElement("br", null),
                "\u0412\u044B \u0431\u0443\u0434\u0435\u0442\u0435 \u0440\u0430\u0437\u043B\u043E\u0433\u0438\u043D\u0435\u043D\u044B \u0441 \u0434\u0440\u0443\u0433\u0438\u0445 \u0443\u0441\u0442\u0440\u043E\u0439\u0441\u0442\u0432")),
            autoHideDuration: 10000,
        }));
        localStorageSafeSet("notification:student-one-login", "true");
    }
};
