import axios from "client/features/request";
import qs from "qs";
export const getStudyGroups = async (params = "") => {
    const { data } = await axios.get(`/api/profile/study-groups/${params}`);
    if (data.status === "error") {
        throw data.error;
    }
    return data.payload.groups;
};
export const getLearningSegmentRange = (segment) => qs.stringify({
    start: segment.get("start"),
    end: segment.get("end"),
}, { addQueryPrefix: true });
