import { createSlice } from "@reduxjs/toolkit";
import axios from "client/features/request";
const initialState = {
    list: [],
    status: "loading",
};
const proctoringRooms = createSlice({
    name: "proctoringRooms",
    initialState,
    reducers: {
        startLoading: (state) => ({
            ...state,
            status: "loading",
        }),
        loaded: (state, action) => ({
            ...state,
            list: action.payload.proctoringRooms,
            status: "loaded",
        }),
    },
});
const { startLoading, loaded } = proctoringRooms.actions;
export const loadProctoringRooms = (silent = false) => async (dispatch) => {
    if (!silent) {
        dispatch(startLoading());
    }
    const { data: { payload: { proctoringRooms }, }, } = await axios.get("/api/proctoring/rooms/");
    dispatch(loaded({
        proctoringRooms,
    }));
};
export default proctoringRooms.reducer;
