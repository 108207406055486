import * as React from "react";
import { connect } from "react-redux";
import StaffActions from "./StaffActions";
import FeedbackAction from "./FeedbackAction";
const UserActions = (props) => {
    const { user } = props;
    if (user.theme.isPresentation) {
        return null;
    }
    if (user.profile.position !== "student") {
        return React.createElement(StaffActions, null);
    }
    return React.createElement(FeedbackAction, null);
};
const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(UserActions);
