import * as React from "react";
import * as R from "ramda";
import cn from "classnames";
import FabBase from "@material-ui/core/Fab";
import { useSpring, animated } from "react-spring";
import { useContainerStyles } from "./common";
const AnimatedFab = animated(FabBase);
const defaultTransform = (state) => {
    if (state.hovered) {
        return {
            transform: "scale(1.2)",
        };
    }
    return {
        transform: "scale(1)",
    };
};
const Fab = (props) => {
    const [hovered, setHovered] = React.useState(false);
    const { getTransform = defaultTransform } = props;
    const initialTransform = React.useCallback(R.once(getTransform), []);
    const fab = useSpring({
        ...getTransform({ hovered }),
        from: initialTransform({ hovered }),
    });
    const handleActive = React.useCallback(() => setHovered(true), []);
    const handleBlur = React.useCallback(() => setHovered(false), []);
    const classes = useContainerStyles({});
    const { children, onClick, className = "" } = props;
    return (React.createElement(AnimatedFab, { style: fab, color: "primary", size: "medium", className: cn(classes.container, className), onMouseOver: handleActive, onMouseOut: handleBlur, onFocus: handleActive, onBlur: handleBlur, onClick: onClick }, children));
};
export default Fab;
