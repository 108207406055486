import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import keycode from "keycode";
import { makeStyles, useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import Container from "@material-ui/core/Container";
import useMedia from "@material-ui/core/useMediaQuery";
import { setUserViewSetting } from "../../actions/user";
import { hideMenu } from "../../actions/menu";
import { getMainMenusData, MENU_WIDTH } from "./utils";
import { getVisibleUserViewSettingsList } from "../../helpers";
import Header from "./Header";
import ErrorBoundary from "../ErrorBoundary";
import Menu from "./Menu";
import UserActions from "../UserActions";
import Footer from "../Footer";
import Loader from "../Loader";
import Dialog from "./Dialog";
import Notification from "./Notification";
import Auth from "./Auth";
import GlobalLoader from "./GlobalLoader";
const useStyles = makeStyles((theme) => ({
    body: {
        display: "flex",
        flexGrow: 1,
        width: "100%",
        height: "100%",
    },
    content: (props) => ({
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: props.user.theme.isPresentation || props.empty ? 0 : -MENU_WIDTH,
        width: props.isMobile || props.user.theme.isPresentation || !props.menu.show || props.empty ? "100%" : "calc(100% - 320px)",
        marginTop: (props.user.theme.isPresentation && !props.isMobile) || props.empty ? "0px" : "64px",
    }),
    contentShift: () => ({
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
    contentInPresentationMode: {
        margin: 0,
        transition: "none",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        height: "100%",
        paddingTop: "35px",
        paddingBottom: "15px",
    },
    main: {
        flex: 1,
    },
    containerWithoutOffset: {
        padding: 0,
    },
    containerFullWidth: {
        maxWidth: "none",
    },
}));
const Layout = ({ title = "", empty = false, fullWidth = false, withoutOffset = false, withoutOffsetOnMobile = false, location, history, children, }) => {
    const [showAuthDialog, setShowAuthDialog] = React.useState(false);
    const user = useSelector((state) => state.user);
    const menu = useSelector((state) => state.menu);
    const globalLoader = useSelector((state) => state.globalLoader);
    const dispatch = useDispatch();
    const isMobile = useMedia(useTheme().breakpoints.down("xs"));
    React.useEffect(() => {
        if (isMobile) {
            dispatch(hideMenu());
        }
    }, [isMobile, dispatch]);
    const classes = useStyles({ menu, isMobile, user, empty });
    // eslint-disable-next-line consistent-return
    React.useEffect(() => {
        const { isPresentation } = user.theme;
        const handleEscClick = (event) => {
            if (keycode(event) !== "esc") {
                return;
            }
            dispatch(setUserViewSetting({
                controlName: "isPresentation",
                value: false,
            }));
        };
        if (isPresentation) {
            window.addEventListener("keydown", handleEscClick);
            return () => window.removeEventListener("keydown", handleEscClick);
        }
    }, [user, dispatch]);
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
    }, []);
    React.useEffect(() => {
        if (title) {
            document.title = title;
        }
    }, [title]);
    if (user.loading) {
        return React.createElement(Loader, { filled: true, absolute: true });
    }
    const controls = getVisibleUserViewSettingsList({
        theme: user.theme,
        location,
        user,
    });
    return (React.createElement("div", { className: classes.body },
        !empty && (React.createElement(Header, { user: user, menu: menu, history: history, location: location, isMobile: isMobile, setShowAuthDialog: setShowAuthDialog })),
        (!user.theme.isPresentation || isMobile) && !empty && (React.createElement(Menu, { open: menu.show, menus: getMainMenusData({ user }), controls: controls, closeMenu: () => dispatch(hideMenu()), isMobile: isMobile, user: user })),
        React.createElement("div", { key: String(isMobile), className: cn({
                [classes.content]: true,
                [classes.contentShift]: menu.show || isMobile,
                [classes.contentInPresentationMode]: user.theme.isPresentation || empty,
            }) },
            React.createElement(ErrorBoundary, null,
                React.createElement(Container, { className: cn({
                        [classes.container]: true,
                        [classes.containerWithoutOffset]: withoutOffset || (withoutOffsetOnMobile && isMobile) || user.theme.isPresentation,
                        [classes.containerFullWidth]: fullWidth || user.theme.isPresentation,
                    }) },
                    React.createElement("div", { className: classes.main }, children),
                    !user.theme.isPresentation && !empty && React.createElement(Footer, null)))),
        !empty && React.createElement(UserActions, { key: user.profile.username }),
        React.createElement(Auth, { showAuthDialog: showAuthDialog, setShowAuthDialog: setShowAuthDialog }),
        React.createElement(Notification, null),
        React.createElement(Dialog, null),
        globalLoader.show && React.createElement(GlobalLoader, null)));
};
export default withRouter(Layout);
