import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import axios from "client/features/request";
import { learningSegmentsLoaded as getLearningSegmentsLoadedStatus } from "client/selectors/learningSegments";
const initialState = {
    status: "loading",
    segments: [],
};
const learningSegments = createSlice({
    name: "learningSegments",
    initialState,
    reducers: {
        startLoadingLearningSegments: (state) => ({
            ...state,
            status: "loading",
            segments: [],
        }),
        learningSegmentsLoaded: (state, action) => ({
            ...state,
            segments: action.payload.segments,
            status: "loaded",
        }),
    },
});
export const { startLoadingLearningSegments, learningSegmentsLoaded } = learningSegments.actions;
export const loadLearningSegments = () => async (dispatch, getState) => {
    const loaded = getLearningSegmentsLoadedStatus(getState());
    if (loaded) {
        return;
    }
    dispatch(startLoadingLearningSegments());
    const { data } = await axios("/api/profile/learning-segments/?all=true");
    const loadedSegments = data.segments
        .sort((a, b) => moment(b.start).valueOf() - moment(a.start).valueOf())
        .map((segment) => ({
        ...segment,
        id: `${segment.start}:${segment.end}`,
    }));
    dispatch(learningSegmentsLoaded({ segments: loadedSegments }));
};
export default learningSegments.reducer;
