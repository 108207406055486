export const localStorageSafeGet = (key, defaultValue) => {
    try {
        return localStorage.getItem(key);
    }
    catch (_a) {
        return defaultValue;
    }
};
export const localStorageSafeSet = (key, value) => {
    try {
        localStorage.setItem(key, value);
    }
    catch (_a) {
        // pass
    }
};
