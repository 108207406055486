import * as R from "ramda";
import { LOCAL_STORAGE_KEY } from "../store/lectureRecord/types";
const initialState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "null") || {
    groupId: "",
    teachingId: "",
    teaching: null,
};
const lectureRecord = (state = initialState, action) => {
    switch (action.type) {
        case "SET_DATA" /* SET_DATA */:
            return {
                ...state,
                groupId: action.payload.groupId,
                teachingId: action.payload.teachingId,
            };
        case "CLEAR_DATA" /* CLEAR_DATA */:
            return R.map(() => "", initialState);
        case "SET_TEACHING" /* SET_TEACHING */:
            return {
                ...state,
                teaching: action.payload,
            };
        default:
            return state;
    }
};
export default lectureRecord;
