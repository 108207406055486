import * as R from "ramda";
import { Position } from "client/typings";
export const viewSettings = {
    isPresentation: {
        name: "Режим презентации",
        show: (params) => params.user.profile.position !== Position.Student,
        order: 1,
    },
    isBigFont: {
        name: "Крупный шрифт",
        show: (params) => params.location.pathname !== "/" && params.user.profile.position !== Position.Student,
        order: 2,
    },
    isManyFormulas: {
        name: "Анимация появления формул",
        show: (params) => params.location.pathname.includes("element") && params.user.profile.position !== Position.Student,
        order: 3,
    },
    isShowWholeText: {
        name: "Пошаговый режим",
        show: (params) => params.location.pathname.includes("element") && params.user.profile.position !== Position.Student,
        order: 4,
        inverted: true,
    },
    /**
     * @todo check that inivisible and doesn't work for students
     */
    isLetterByLetterModeEnabled: {
        name: "Появление математического текста по буквам",
        show: (params) => params.location.pathname.includes("element") && params.user.profile.position !== Position.Student && !params.user.theme.isShowWholeText,
        order: 5,
    },
    isShowPreview: {
        name: "Предпросмотр квантов",
        show: (params) => params.location.pathname.includes("element"),
        order: 6,
    },
    isMultiWindow: {
        name: "Открывать ссылки в новых вкладках",
        show: (params) => params.location.pathname !== "/",
        order: 7,
    },
    isParagraphVisibleOnElementPage: {
        name: "Отображать круг параграфа на странице кванта",
        show: (params) => params.location.pathname.includes("element") && params.user.profile.position !== Position.Student,
        order: 8,
    },
    isMathTextClickable: {
        name: "Появление формул при клике на мат. текст",
        show: (params) => params.location.pathname.includes("element") && params.user.profile.position !== Position.Student,
        order: 9,
    },
    isAdminWebPageElementsEnabled: {
        name: "Отображать элементы редактирования страницы (для администраторов)",
        show: (params) => params.user.profile.position === Position.Admin,
        order: 10,
    },
};
export const getVisibleUserViewSettingsList = (p) => R.compose(R.filter(({ show }) => show({ location: p.location, user: p.user })), R.sortBy(R.prop("order")), (settingsWithValues) => R.map(([key, value]) => R.set(R.lensPath(["id"]), key)(value), R.zip(R.keys(settingsWithValues), R.values(settingsWithValues))), R.mergeDeepLeft(viewSettings), R.mapObjIndexed((value) => ({
    value,
})), R.pick(R.keys(viewSettings)))(p.theme);
