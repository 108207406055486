import qs from "qs";
import axios from "client/features/request";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    loaded: false,
    tree: {},
};
const controlsTree = createSlice({
    name: "controlsTree",
    initialState,
    reducers: {
        startLoading: (state) => ({
            ...state,
            loaded: false,
        }),
        controlsTreeLoaded: (state, action) => ({
            ...state,
            loaded: true,
            tree: action.payload.tree,
        }),
    },
});
const { controlsTreeLoaded, startLoading } = controlsTree.actions;
export const loadControlsTree = (payload) => async (dispatch) => {
    dispatch(startLoading());
    const { start, end, getActive } = payload;
    const { data } = await axios.get(`/api/educational-testing/educational-modules/form-data/${qs.stringify({ start, end }, { addQueryPrefix: true })}`);
    if (data.status === "error") {
        throw data.error;
    }
    const active = getActive();
    if (start !== active.start || end !== active.end) {
        return;
    }
    dispatch(controlsTreeLoaded({
        tree: data.payload.controlsTree,
    }));
};
export default controlsTree.reducer;
