import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});
export default store;
