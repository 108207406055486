import sleep from "client/helpers/sleep";
import { Position } from "client/typings";
import { getCSRFToken } from "client/helpers";
import { initialState } from "client/reducers/user";
import axios, { updateToken } from "client/features/request";
import { displayNotificationsOnAuth } from "./displayNotificationsOnAuth";
/**
 * Устанавливает данные пользователя
 * @param {Object} payload
 * @param {string} payload.username
 * @param {string} payload.firstName
 * @param {string} payload.lastName
 * @param {string} payload.email
 * @param {boolean} payload.isSuperuser
 * @param {string} payload.position – студент|преподователь|администратор
 */
const setUserData = (payload) => ({
    type: "USER_DATA",
    payload,
});
export const setUserDataLoading = (payload) => ({
    type: "LOADING",
    payload,
});
/**
 * Устанавливает в булево значение статуст авторизации пользователя
 * @param {Object} param
 * @param {boolean} param.isAuthenticated - авторизован ли пользователь
 */
export const setAuthenticationStatus = (payload) => ({
    type: "AUTHENTICATION",
    payload: payload.isAuthenticated,
});
export const loadUpdateGraphState = async () => (await axios.get("/api/graphs/update/state/")).data;
export const actionUpdateGraphsState = (payload) => ({
    type: "UPDATE_GRAPHS_STATE",
    payload,
});
export const loadUserData = (silently = false, source) => async (dispatch) => {
    if (!silently) {
        try {
            await updateToken();
        }
        catch (_a) {
            // pass
        }
        dispatch(setUserDataLoading(true));
    }
    const response = await axios.get("/api/user/");
    const { isAuthenticated, ...rest } = response.data;
    dispatch(setAuthenticationStatus({ isAuthenticated }));
    if (isAuthenticated) {
        const { theme, ...userProfile } = rest;
        if (userProfile.position === Position.Admin) {
            dispatch(actionUpdateGraphsState((await loadUpdateGraphState()).state));
        }
        dispatch(setUserData({ theme, profile: userProfile }));
    }
    else {
        dispatch(setUserData({
            theme: initialState.theme,
            profile: initialState.profile,
        }));
    }
    if (!silently) {
        await sleep();
        dispatch(setUserDataLoading(false));
    }
    if (source === "auth") {
        dispatch(displayNotificationsOnAuth());
    }
};
const setViewSettings = (payload) => ({
    type: "VIEW_SETTING",
    payload,
});
export const setUserViewSetting = (p) => async (dispatch) => {
    dispatch(setViewSettings({
        path: p.controlName,
        value: p.value,
    }));
    await axios.post("/api/user/", {
        parameter: p.controlName.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase(),
    }, {
        withCredentials: true,
        headers: {
            "X-CSRFToken": await getCSRFToken(),
        },
    });
    await dispatch(loadUserData(true));
};
