import { createSlice } from "@reduxjs/toolkit";
import sleep from "../helpers/sleep";
const initialState = {
    open: false,
    title: "",
    content: "",
    buttons: "OK",
};
const dialog = createSlice({
    name: "dialog",
    initialState,
    reducers: {
        openDialog: (state, action) => ({
            ...state,
            ...action.payload,
            open: true,
        }),
        closeDialogImmediately: (state) => ({
            ...state,
            open: false,
        }),
        clearDialogState: () => initialState,
    },
});
export const { openDialog, closeDialogImmediately, clearDialogState } = dialog.actions;
export const closeDialog = () => async (dispatch) => {
    dispatch(closeDialogImmediately());
    await sleep(500);
    dispatch(clearDialogState());
};
export default dialog.reducer;
