import React, { Suspense } from "react";
import { Route, useHistory } from "react-router-dom";
import Loader from "client/components/Loader";
import routes from "./routes";
import { virtualView } from "./analytics";
import Layout from "../components/Layout";
import { getRouteProps } from "./utils";
import { useRoute } from "./hooks";
const handlers = routes.map(({ analyticProps, layoutProps, component, ...props }) => {
    const Component = component;
    return React.createElement(Route, Object.assign({ key: props.path }, props, { component: ({ match, ...rest }) => React.createElement(Component, Object.assign({}, match.params, rest)) }));
});
const handleNavigate = (location) => {
    const { analyticProps = {} } = getRouteProps(location.pathname);
    if (analyticProps.manual) {
        return;
    }
    virtualView();
};
const Routes = () => {
    const history = useHistory();
    React.useEffect(() => {
        history.listen(handleNavigate);
        handleNavigate(history.location);
    }, [history]);
    const { layoutProps = {} } = useRoute();
    return (React.createElement(Layout, Object.assign({}, layoutProps),
        React.createElement(Suspense, { fallback: React.createElement(Loader, { grow: true }) }, handlers)));
};
export * from "./hooks";
export * from "./types";
export default Routes;
