import React from "react";
import { makeStyles } from "@material-ui/styles";
import filesize from "filesize";
import shortid from "shortid";
import { useDropzone } from "react-dropzone";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import File from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import { maxFileInputSize } from "./utils";
const useStyles = makeStyles({
    dropzone: {
        color: "rgba(0, 0, 0, 0.5)",
        margin: "16px 16px 16px 0",
        position: "relative",
        width: "200px",
        height: "200px",
        borderWidth: "2px",
        borderColor: "rgba(0, 0, 0, 0.5)",
        borderStyle: "dashed",
        borderRadius: "5px",
        cursor: "pointer",
        "&:before": {
            content: '"+"',
            position: "absolute",
            fontSize: "40px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
        "&:hover": {
            color: "rgba(0, 0, 0, 0.7)",
            borderColor: "rgba(0, 0, 0, 0.7)",
        },
    },
});
const FileUploader = (props) => {
    const { name, files, setFieldValue } = props;
    const classes = useStyles();
    const [show, setShow] = React.useState(false);
    const [dialog, setDialog] = React.useState({
        name: "",
        size: 0,
    });
    const handleFilesDrop = (accepted, rejected) => {
        if (rejected.length) {
            const largeFile = rejected.find((rejection) => rejection.file.size > maxFileInputSize);
            if (!largeFile) {
                return;
            }
            setDialog({
                name: largeFile.file.name,
                size: largeFile.file.size,
            });
            setShow(true);
            return;
        }
        const acceptedFilesWithId = accepted.map((file) => ({
            source: file,
            id: shortid(),
        }));
        setFieldValue(name, [...files, ...acceptedFilesWithId], false);
    };
    const handleDeleteFile = (idOfDeletingFile) => {
        const filesWithoutDeleted = files.filter(({ id }) => id !== idOfDeletingFile);
        setFieldValue(name, filesWithoutDeleted, false);
    };
    const { getInputProps, getRootProps } = useDropzone({
        onDrop: handleFilesDrop,
        maxSize: maxFileInputSize,
    });
    const handleDialogClose = React.useCallback(() => setShow(false), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(List, null, files.map((file) => (React.createElement(ListItem, { key: file.id },
            React.createElement(ListItemAvatar, null,
                React.createElement(Avatar, null,
                    React.createElement(File, null))),
            React.createElement(ListItemText, { primary: `${file.source.name} (${filesize(file.source.size)})` }),
            React.createElement(ListItemSecondaryAction, null,
                React.createElement(IconButton, { "aria-label": "Delete", onClick: () => handleDeleteFile(file.id) },
                    React.createElement(DeleteIcon, null))))))),
        React.createElement("div", Object.assign({ className: classes.dropzone }, getRootProps()),
            React.createElement("input", Object.assign({}, getInputProps()))),
        React.createElement(Dialog, { open: show, onClose: handleDialogClose },
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, null,
                    "\u0424\u0430\u0439\u043B",
                    " ",
                    React.createElement(Typography, { component: "em", display: "inline" }, `${dialog.name} `),
                    React.createElement(Typography, { component: "em", display: "inline", color: "error" },
                        "(",
                        filesize(dialog.size),
                        ")"),
                    "\u043F\u0440\u0435\u0432\u044B\u0448\u0430\u0435\u0442 \u0434\u043E\u043F\u0443\u0441\u0442\u0438\u043C\u044B\u0439 \u0440\u0430\u0437\u043C\u0435\u0440 \u0432",
                    React.createElement(Typography, { component: "em", display: "inline", color: "error" },
                        filesize(maxFileInputSize),
                        "."))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: handleDialogClose, variant: "contained", color: "primary" }, "OK")))));
};
export default FileUploader;
