import * as R from "ramda";
import { Position } from "client/typings";
import { AUTHENTICATION, USER_DATA, VIEW_SETTING, UPDATE_GRAPHS_STATE, LOADING } from "../store/user/types";
export const initialState = {
    loading: true,
    isAuthenticated: false,
    profile: {
        id: "",
        pk: -1,
        username: "",
        firstName: "",
        lastName: "",
        isSuperuser: false,
        position: Position.Student,
        email: "",
        hasTOTP: false,
    },
    theme: {
        isMultiWindow: false,
        isPresentation: false,
        isBigFont: false,
        isManyFormulas: false,
        isShowWholeText: false,
        isShowPreview: false,
        isMathTextClickable: false,
        isLetterByLetterModeEnabled: false,
        isAdminWebPageElementsEnabled: false,
        isParagraphVisibleOnElementPage: true,
    },
    updateGraph: {
        state: "DONE",
    },
};
export default (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATION:
            return { ...state, isAuthenticated: action.payload };
        case USER_DATA:
            return { ...state, ...action.payload };
        case VIEW_SETTING: {
            const { path, value } = action.payload;
            return {
                ...state,
                theme: R.assoc(path, value, state.theme),
            };
        }
        case UPDATE_GRAPHS_STATE:
            return {
                ...state,
                updateGraph: { state: action.payload },
            };
        case LOADING:
            return { ...state, loading: action.payload };
        default:
            return state;
    }
};
