import React from "react";
import cn from "classnames";
import { trim } from "ramda";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import { useRoute } from "client/routes";
import { getUser } from "client/selectors";
import { checkPagePermission } from "client/helpers";
import Loader from "client/components/Loader";
import { RECOVERY_CATEGORY } from "client/components/Layout/constants";
import { useAuth } from "./useAuth";
const useStyles = makeStyles(() => ({
    input: {
        marginTop: "15px",
    },
    form: {
        position: "relative",
    },
    dialogContent: {
        paddingTop: "15px !important",
        paddingBottom: "0",
        overflowY: "visible",
    },
    dialogPaper: {
        width: "100%",
    },
    shakePaper: {
        animationName: "$shake",
        animationTimingFunction: "linear",
        animationDuration: ".5s",
    },
    authTitle: {
        fontSize: "1.3rem",
        fontWeight: "bold",
        marginBottom: 0,
        margin: "4px 0",
    },
    actions: {
        margin: (params) => `${(params === null || params === void 0 ? void 0 : params.showTokenInput) ? "36" : "25"}px 15px 12px 0`,
    },
    message: {
        marginTop: "20px",
    },
    tokenCollapse: {
        position: "relative",
    },
    resend: {
        position: "absolute",
        cursor: "pointer",
        right: 0,
        bottom: "-24px",
    },
    "@keyframes shake": {
        "8%": {
            transform: "translateX(-10px)",
        },
        "41%": {
            transform: "translateX(-10px)",
        },
        "25%": {
            transform: "translateX(10px)",
        },
        "58%": {
            transform: "translateX(10px)",
        },
        "75%": {
            transform: "translateX(-5px)",
        },
        "92%": {
            transform: "translateX(5px)",
        },
        "0%": {
            transform: "translateX(0)",
        },
        "100%": {
            transform: "translateX(0)",
        },
    },
}));
const Auth = ({ showAuthDialog, setShowAuthDialog }) => {
    const auth = useAuth({ setShowAuthDialog });
    const s = useStyles({
        showTokenInput: auth.state.showTokenInput,
    });
    const [onAuthDialogClose, setAuthDialogCloseHandler] = React.useState(() => () => { });
    const route = useRoute();
    const history = useHistory();
    const location = useLocation();
    const user = useSelector(getUser);
    React.useEffect(() => {
        if ((user === null || user === void 0 ? void 0 : user.loading) || (user === null || user === void 0 ? void 0 : user.isAuthenticated)) {
            return;
        }
        const canSeePageWithoutAuth = checkPagePermission({ location, route });
        if (canSeePageWithoutAuth) {
            return;
        }
        setShowAuthDialog(true);
        setAuthDialogCloseHandler(() => () => {
            history.replace("/");
        });
    }, [user, route, location, history]);
    return (React.createElement(Dialog, { open: showAuthDialog, onClose: () => {
            setShowAuthDialog(false);
            setTimeout(() => {
                auth.dispatch({ type: "CLEAR" });
                onAuthDialogClose();
            }, 0);
        }, maxWidth: "xs", classes: {
            paper: cn(s.dialogPaper, auth.state.showAuthErrorAnimation && s.shakePaper),
        } },
        React.createElement("form", { className: s.form, action: "/api/profile/login", onSubmit: auth.handleAuthClick },
            React.createElement(Loader, { filled: true, loading: auth.state.loading, absolute: true }),
            React.createElement(DialogContent, { className: s.dialogContent },
                React.createElement(DialogContentText, { className: s.authTitle }, auth.state.reset ? "Восстановить пароль" : "Вход в Nomotex"),
                auth.state.message ? (React.createElement(Typography, { className: s.message, variant: "body1" }, auth.state.message)) : (React.createElement(React.Fragment, null,
                    React.createElement(TextField, { label: "\u041B\u043E\u0433\u0438\u043D", name: "username", className: s.input, value: auth.state.username, onChange: (event) => {
                            auth.dispatch({
                                type: "SET",
                                payload: {
                                    username: trim(event.target.value || ""),
                                },
                            });
                            auth.dispatch({ type: "CLEAR_ERRORS" });
                        }, error: !!auth.state.usernameError, helperText: auth.state.usernameError, autoFocus: true, required: true, fullWidth: true }),
                    React.createElement(Collapse, { in: !auth.state.reset, unmountOnExit: true },
                        React.createElement(TextField, { label: "\u041F\u0430\u0440\u043E\u043B\u044C", name: "password", type: "password", className: s.input, value: auth.state.password, onChange: (event) => {
                                auth.dispatch({
                                    type: "SET",
                                    payload: {
                                        password: event.target.value,
                                    },
                                });
                                auth.dispatch({ type: "CLEAR_ERRORS" });
                            }, required: true, fullWidth: true })),
                    React.createElement(Collapse, { in: auth.state.showTokenInput, className: s.tokenCollapse, unmountOnExit: true },
                        React.createElement(TextField, { label: auth.state.totp ? "Токен" : "Код из сообщения", name: "token", type: "text", className: s.input, value: auth.state.token, onChange: (event) => {
                                const token = trim(event.target.value || "");
                                const tokenLength = auth.state.totp ? 6 : 4;
                                if (typeof token === "string" && token.length === tokenLength) {
                                    auth.verifyToken({ token });
                                }
                                auth.dispatch({ type: "CLEAR_ERRORS" });
                                auth.dispatch({
                                    type: "SET",
                                    payload: {
                                        token,
                                    },
                                });
                            }, error: !!auth.state.tokenError, helperText: auth.state.tokenError, required: true, fullWidth: true, autoFocus: true }),
                        !auth.state.totp && (React.createElement(Link, { variant: "body2", onClick: () => {
                                auth.dispatch({ type: "CLEAR_ERRORS" });
                                auth.dispatch({
                                    type: "SET",
                                    payload: {
                                        token: "",
                                    },
                                });
                                auth.handleAuthClick(null, true);
                            }, className: s.resend }, "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043D\u043E\u0432\u044B\u0439 \u043A\u043E\u0434")))))),
            React.createElement(DialogActions, { className: s.actions }, auth.state.message ? (React.createElement(Button, { color: "primary", variant: "contained", type: "button", onClick: () => {
                    setShowAuthDialog(false);
                    setTimeout(() => {
                        auth.dispatch({ type: "CLEAR" });
                    }, 0);
                    auth.dispatch({
                        type: "SET",
                        payload: {
                            username: "",
                        },
                    });
                } }, "OK")) : (React.createElement(React.Fragment, null,
                React.createElement(Button, { color: "primary", variant: "contained", type: "submit" }, auth.state.reset ? "Продолжить" : "Войти"),
                React.createElement(Button, { color: "primary", variant: "text", onClick: () => {
                        auth.dispatch({
                            type: "SET",
                            payload: {
                                reset: !auth.state.reset,
                            },
                        });
                        if (auth.state.reset) {
                            gtag("event", "cancel", {
                                event_category: RECOVERY_CATEGORY,
                            });
                        }
                        else {
                            gtag("event", "start", {
                                event_category: RECOVERY_CATEGORY,
                            });
                        }
                    } }, auth.state.reset ? "Отмена" : "Забыли пароль?")))))));
};
export default Auth;
