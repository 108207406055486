import { createSlice } from "@reduxjs/toolkit";
import axios from "client/features/request";
const initialState = {
    loaded: false,
    groups: [],
};
const studyGroups = createSlice({
    name: "studyGroups",
    initialState,
    reducers: {
        studyGroupsLoaded: (state, action) => ({
            ...state,
            loaded: true,
            groups: action.payload.groups,
        }),
    },
});
const { studyGroupsLoaded } = studyGroups.actions;
export const loadStudyGroups = () => async (dispatch) => {
    const { data } = await axios.get("/api/profile/study-groups/");
    if (data.status === "error") {
        throw data.error;
    }
    dispatch(studyGroupsLoaded({ groups: data.payload.groups }));
};
export default studyGroups.reducer;
