import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { hideSnackbar } from "client/actions/snackbar";
const Notification = () => {
    const dispatch = useDispatch();
    const snackbar = useSelector((state) => state.snackbar);
    const content = useMemo(() => {
        if (snackbar.type === "default") {
            return (React.createElement(SnackbarContent, { message: React.createElement(Typography, { variant: "body1" }, snackbar.message), action: React.createElement(IconButton, { color: "inherit", onClick: () => dispatch(hideSnackbar()) },
                    React.createElement(CloseIcon, null)) }));
        }
        return snackbar.message;
    }, [dispatch, snackbar]);
    if (!snackbar.message) {
        return null;
    }
    return (React.createElement(Snackbar, { open: snackbar.open, anchorOrigin: snackbar.anchor, onClose: () => dispatch(hideSnackbar()), autoHideDuration: snackbar.autoHideDuration || 5000 }, content));
};
export default Notification;
