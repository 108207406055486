import { createAction } from "@reduxjs/toolkit";
import axios from "../features/request";
export const setEducationalTrainingModules = createAction("SET_EDUCATIONAL_TRAINING_MODULES");
export const loadEducationalTrainingModules = (pk) => async (dispatch) => {
    if (!pk) {
        return;
    }
    const eduTasksR = await axios.get(`/api/educational-testing/educational-tasks/?course=${pk}`);
    dispatch(setEducationalTrainingModules({
        pk,
        eduModules: eduTasksR.data.payload.modules,
    }));
};
