import * as R from "ramda";
import swal from "sweetalert";
import axios from "client/features/request";
/**
 * Возвращает ссылку на страницу "Коллекция математической визуализации"
 * @param {Object} param
 * @param {string} param.discipline
 * @param {string} param.section
 */
export const getVisualizationPageUrl = (p) => {
    const baseUrl = `/visualization/${p.discipline}/${p.section}/`;
    if (!p.exampleId) {
        return baseUrl;
    }
    return `${baseUrl}${p.exampleId}/`;
};
/**
 * Возвращает CSRF-токен
 * @returns {string} csrftoken
 */
export const getCSRFToken = async () => {
    const response = await axios.get("/api/csrf/");
    return response.data.csrfToken;
};
export const showAlert = (content, callback = () => { }) => {
    const swalContent = document.createElement("div");
    swalContent.innerHTML = `
        <div style="margin-top: 50px; font-size: 1.2rem;">
           ${content}
        </div>
    `;
    swal({ content: { element: swalContent } }).then(callback);
};
/**
 * Возвращает глубокую копию объекта.
 * @param {object} obj
 */
export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
/**
 * Загружает образовательные стандарты.
 */
export const loadEducationalStandards = async () => {
    const response = await axios.get("/api/educational-direction/educational-standards/");
    return response.data;
};
export const loadEducationalPrograms = async () => {
    var _a, _b;
    const response = await axios.get("/api/educational-direction/educational-programs/");
    return (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.payload) === null || _b === void 0 ? void 0 : _b.educationalPrograms;
};
/**
 * Проверяет по событию пытается ли пользователь открыть ссылку в новой вкладке.
 * @param {object} param
 * @param {window.Event} param.event
 * @param {bool} param.isMultiWindow - переключатель в меня для открытия ссылок в новой вкладке.
 */
const checkIfTryingOpenLinkInNewTab = (p) => {
    if (p.isMultiWindow) {
        return true;
    }
    if (p.event.ctrlKey ||
        p.event.shiftKey ||
        p.event.metaKey || // Mac (command)
        (p.event.button && p.event.button === 1) // Среднее колёсико мыши.
    ) {
        return true;
    }
    return false;
};
/**
 * Вспомогательная функция для обработки событий кликов по ссылкам.
 * Открывает ссылки в зависимости от настроек пользователя
 * или зажатых клавиш управления (Ctrl/Command).
 *
 * @returns {bool} - возвращает true, если ссылка открывается в новой вкладке.
 */
export const openUrl = (p) => {
    const { url, event, history, isMultiWindow } = p;
    event.preventDefault();
    const isNewTab = checkIfTryingOpenLinkInNewTab({
        event,
        isMultiWindow,
    });
    if (isNewTab) {
        window.open(url, "_blank");
        return true;
    }
    history.push(url);
    return false;
};
export const checkPagePermission = (params) => {
    const { location, route: { canViewPageUnauthorized } = {} } = params;
    if (canViewPageUnauthorized) {
        return canViewPageUnauthorized({ location });
    }
    const pagesWithoutPermissions = [
        {
            path: "/",
            exact: true,
        },
        {
            path: "about",
            exact: false,
        },
        {
            path: "confirm-reset-password",
            exact: false,
        },
        {
            path: "confrences/home",
            exact: false,
        },
    ];
    return pagesWithoutPermissions.some(({ path, exact }) => {
        if (exact) {
            return location.pathname === path;
        }
        return location.pathname.includes(path);
    });
};
export const clearDevUrl = (path) => R.replace("http://localhost:3000", "", path);
export * from "./theme";
