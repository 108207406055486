import React from "react";
import * as R from "ramda";
import { useDispatch } from "react-redux";
import { Link as LinkRoute } from "react-router-dom";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Drawer from "@material-ui/core/Drawer";
import ListMaterialUI from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItemMaterialUI from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemTextBase from "@material-ui/core/ListItemText";
import ExpansionPanelMaterialUI from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { FontAwesomeIcon as FontAwesomeIconFort } from "@fortawesome/react-fontawesome";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { setUserViewSetting } from "../../actions/user";
import { MENU_WIDTH } from "./utils";
const ExpansionPanel = styled(({ isMobile, ...rest }) => React.createElement(ExpansionPanelMaterialUI, Object.assign({}, rest))) `
    && {
        min-height: 64px;
        box-shadow: none;
        background: ${({ isMobile }) => (isMobile ? "white" : "rgba(240, 240, 240, 0.5)")};
    }
`;
const CollapsableListItem = styled.div `
    min-height: 64px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;
const MainMenuTabsList = styled(ListMaterialUI) `
    && {
        padding: 0;
    }
`;
const ListItem = styled(ListItemMaterialUI) `
    && {
        min-height: 64px;
        padding: 0 24px 0 24px;
    }
`;
const ListItemText = styled(({ withOffset, ...rest }) => React.createElement(ListItemTextBase, Object.assign({}, rest))) `
    && {
        ${({ withOffset }) => (withOffset ? "padding: 10px 15px;" : "")}
    }
`;
const SubMenuTabsList = styled(({ withoutOffset, ...rest }) => React.createElement(ListItemMaterialUI, Object.assign({}, rest))) `
    && {
        display: flex;
        flex-direction: column;
        ${({ withoutOffset }) => (withoutOffset ? "padding: 0;" : "")}
        box-shadow:
            0px 1px 3px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
`;
const SubMenuTabsListItem = styled(ListItem) `
    && {
        padding: 0;
        text-align: center;
    }
`;
const DrawerHeader = styled.div `
    display: flex;
    align-items: center;
    padding: 0 8px;
    justify-content: flex-end;
    min-height: 64px;
`;
const FontAwesomeIcon = styled(FontAwesomeIconFort) `
    && {
        width: 15px;
        height: 15px;
    }
`;
const LinkWrapper = React.forwardRef(({ active, rawLink, to, children, isMobile, ...rest }, ref) => rawLink ? (React.createElement("a", Object.assign({ href: to, ref: ref }, rest), children)) : (React.createElement(LinkRoute, Object.assign({ innerRef: ref, to: to }, rest), children)));
const Link = styled(LinkWrapper) `
    display: block;
    width: 100%;
    text-decoration: none;
    outline: none;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    color: rgba(0, 0, 0, 0.87);
    background-color: ${({ active, isMobile }) => {
    if (active) {
        return "rgba(221, 221, 221, 0.9)";
    }
    if (isMobile) {
        return "white";
    }
    return "rgba(240, 240, 240, 0.5)";
}};

    &:hover {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.87);
    }

    &:active {
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }

    &::selection {
        background: transparent;
    }
`;
const checkActive = ({ includesCompare = false, href }) => {
    if (includesCompare) {
        return window.location.pathname.includes(href);
    }
    return window.location.pathname === href;
};
const MenuPanel = ({ title, icon, iconColor, href = "", links = [], onClick = () => { }, getViewProps = () => ({}), getTooltipText = null, rawLink = false, includesCompare = false, expansionContent, expansionRoot, onClose, isMobile, }) => {
    const dispatch = useDispatch();
    if (href) {
        const TooltipComponent = getTooltipText ? Tooltip : React.Fragment;
        const props = {
            title: (React.createElement(Typography, { variant: "body1", align: "center" }, getTooltipText && getTooltipText())),
            placement: "right",
        };
        return (React.createElement(TooltipComponent, Object.assign({}, (getTooltipText ? props : null)),
            React.createElement(Link, Object.assign({ to: href, rawLink: rawLink, onClick: (event) => {
                    if (isMobile) {
                        onClose();
                    }
                    onClick({ event, dispatch });
                }, active: checkActive({ includesCompare, href }), isMobile: isMobile }, getViewProps()),
                React.createElement(ListItem, Object.assign({ button: true, divider: true }, getViewProps()),
                    React.createElement(ListItemIcon, null,
                        React.createElement(FontAwesomeIcon, { color: iconColor, icon: icon, size: "sm", fixedWidth: true })),
                    React.createElement(ListItemText, null, title)))));
    }
    const isAnyTabOpen = R.any(R.propEq("href", window.location.pathname), links);
    return (React.createElement(CollapsableListItem, null,
        React.createElement(ExpansionPanel, { defaultExpanded: isAnyTabOpen, isMobile: isMobile },
            React.createElement(ExpansionPanelSummary, { expandIcon: React.createElement(ExpandMoreIcon, null), classes: {
                    root: expansionRoot,
                    content: expansionContent,
                } },
                React.createElement(ListItemIcon, null,
                    React.createElement(FontAwesomeIcon, { icon: icon, size: "sm", color: iconColor })),
                React.createElement(ListItemText, { primary: title })),
            React.createElement(ExpansionPanelDetails, null,
                React.createElement(SubMenuTabsList, { withoutOffset: true }, links.map((link, idx) => (React.createElement(Link, { to: link.href, key: link.href, onClick: () => {
                        if (isMobile) {
                            onClose();
                        }
                    }, active: !!checkActive({ ...link, includesCompare }), isMobile: isMobile },
                    React.createElement(SubMenuTabsListItem, { button: true, 
                        // Убираем бордер у последнего элемента в списке
                        divider: idx !== links.length - 1 },
                        React.createElement(ListItemText, { withOffset: true }, link.title))))))))));
};
const useStyles = makeStyles({
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    drawer: {
        width: `${MENU_WIDTH}px`,
    },
    drawerPaper: {
        width: `${MENU_WIDTH}px`,
        background: ({ isMobile }) => (isMobile ? "white" : "rgba(240, 240, 240, 0.5)"),
    },
    expansionRoot: {
        padding: "0 24px 0 24px",
    },
    expansionContent: {
        display: "flex",
        alignItems: "center",
        height: "64px",
        margin: "0",
    },
    controlsContainer: {
        marginTop: "50px",
    },
    controlWrapper: {
        display: "grid",
        grid: "1fr / 1fr 45px",
        padding: "10px 25px",
        alignItems: "center",
        justifyContent: "center",
    },
    dialogText: {
        paddingTop: "24px",
    },
});
const Menu = React.memo(({ open, menus, controls, closeMenu, isMobile, user }) => {
    const classes = useStyles({ isMobile });
    const dispatch = useDispatch();
    const [dialog, setDialog] = React.useState(null);
    const handleClose = React.useCallback(() => {
        setDialog(null);
    }, [setDialog]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Drawer, { variant: isMobile ? "temporary" : "persistent", anchor: "left", open: open, className: classes.drawer, classes: {
                paper: classes.drawerPaper,
            }, onClose: closeMenu },
            React.createElement(DrawerHeader, null),
            React.createElement(Divider, null),
            React.createElement(MainMenuTabsList, null, menus.map((tabData) => (React.createElement(MenuPanel, Object.assign({ key: tabData.title }, tabData, { expansionContent: classes.expansionContent, expansionRoot: classes.expansionRoot, onClose: closeMenu, isMobile: isMobile }))))),
            user.isAuthenticated && (React.createElement("div", { className: classes.controlsContainer }, R.map((control) => (React.createElement("div", { className: classes.controlWrapper, key: control.id },
                React.createElement(Typography, null, control.name),
                React.createElement(Switch, { checked: control.inverted ? !control.value : control.value, onChange: (_, checked) => {
                        if (control.id === "isPresentation") {
                            setDialog({
                                text: "Для выхода из режима презентации нажмите Esc.\nДля пролистывания слайдов используйте Shift+\u2193 и Shift+\u2191 (для переключения элементов пошагового режима просто стрелочки вверх и вниз: \u2193 и \u2191).\nДля включения/отключения видимости элементов управления используйте клавишу v.",
                                onClick: () => {
                                    dispatch(setUserViewSetting({
                                        controlName: control.id,
                                        value: checked,
                                    }));
                                    handleClose();
                                },
                            });
                            return;
                        }
                        dispatch(setUserViewSetting({
                            controlName: control.id,
                            value: control.inverted ? !checked : checked,
                        }));
                    } }))), controls)))),
        React.createElement(Dialog, { open: dialog !== null, onClose: handleClose },
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, { className: classes.dialogText }, dialog ? dialog.text : "")),
            React.createElement(DialogActions, null,
                React.createElement(Button, { variant: "outlined", onClick: dialog ? dialog.onClick : () => { } }, "\u041E\u041A"),
                React.createElement(Button, { variant: "contained", color: "secondary", onClick: handleClose }, "\u041E\u0442\u043C\u0435\u043D\u0430")))));
});
export default Menu;
