/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import LinkBase from "@material-ui/core/Link";
const Container = styled.div `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
const Link = styled(LinkBase) `
    cursor: pointer;
`;
export default class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            error: null,
        };
        this.handleReload = () => {
            window.location.reload();
        };
    }
    static getDerivedStateFromError(error) {
        return {
            error,
        };
    }
    render() {
        const { error } = this.state;
        if (error) {
            return (React.createElement(Container, null,
                React.createElement(Typography, { variant: "h5" },
                    "\u0427\u0442\u043E-\u0442\u043E \u043F\u043E\u0448\u043B\u043E \u043D\u0435 \u0442\u0430\u043A",
                    " ",
                    React.createElement("span", { "aria-label": "sad", role: "img" }, "\uD83D\uDE1E")),
                React.createElement(Typography, { variant: "h5" },
                    "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 ",
                    React.createElement(Link, { onClick: this.handleReload }, "\u043F\u0435\u0440\u0435\u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443"))));
        }
        const { children } = this.props;
        return children;
    }
}
