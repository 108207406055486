import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import DialogUI from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { closeDialog } from "client/slices/dialog";
import { open, getDialog } from "./selectors";
const Dialog = React.memo(() => {
    const isOpen = useSelector(open);
    const dialog = useSelector(getDialog);
    const dispatch = useDispatch();
    const handleClose = useMemo(() => {
        if (!dialog) {
            return () => { };
        }
        if (dialog.onClose) {
            return dialog.onClose;
        }
        return () => dispatch(closeDialog());
    }, [dialog, dispatch]);
    const buttons = useMemo(() => {
        if (!dialog) {
            return null;
        }
        if (dialog.buttons === "OK") {
            return (React.createElement(Button, { color: "primary", variant: "outlined", onClick: handleClose }, dialog.buttons));
        }
        return dialog.buttons;
    }, [dialog, handleClose]);
    const content = useMemo(() => {
        if (!dialog) {
            return null;
        }
        if (typeof dialog.content === "string") {
            return (React.createElement(DialogContent, null,
                React.createElement(DialogContentText, null, dialog.content)));
        }
        return React.createElement(DialogContent, null, dialog.content);
    }, [dialog]);
    if (!dialog) {
        return null;
    }
    return (React.createElement(DialogUI, { open: isOpen, onClose: handleClose },
        dialog.title && React.createElement(DialogTitle, null, dialog.title),
        content,
        React.createElement(DialogActions, null, buttons)));
});
export default Dialog;
