import React from "react";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabContainer from "./TabContainer";
const useStyles = makeStyles((theme) => ({
    menuHeader: {
        display: "flex",
        alignItems: "center",
        fontSize: (params) => (params.bigFont ? "1.3rem" : "0.9rem"),
        color: "#f0f0ff",
        padding: (params) => (params.bigFont ? 0 : "0 14px"),
        minHeight: "38px",
        [theme.breakpoints.down("sm")]: {
            padding: (params) => (params.bigFont ? 0 : "0 12px"),
        },
    },
}));
const getIcon = ({ icon, iconColor }) => {
    if (React.isValidElement(icon) || icon === null) {
        return icon;
    }
    return React.createElement(FontAwesomeIcon, { icon: icon, color: iconColor, size: "sm" });
};
const MenuTab = ({ title, icon = null, iconColor = "", bigFont = false, href = "", onClick = () => { }, className = "", hideOnMobile = false, }) => {
    const s = useStyles({ bigFont });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
        defaultMatches: true,
    });
    if (isMobile && hideOnMobile) {
        return null;
    }
    return (React.createElement(TabContainer, { className: className, href: href, onClick: onClick },
        getIcon({ icon, iconColor }),
        React.createElement("div", { className: s.menuHeader }, title)));
};
export default MenuTab;
