import React from "react";
import { Link as LinkBase } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
    link: {
        fontSize: "inherit",
        color: "inherit",
        textDecoration: "none",
        "&:hover": {
            color: "inherit",
            textDecoration: "none",
        },
    },
});
const Link = (props) => {
    const classes = useStyles({});
    return React.createElement(LinkBase, Object.assign({ className: classes.link }, props));
};
export default Link;
