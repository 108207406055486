import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
export const initSentry = () => {
    Sentry.init({
        dsn: "https://4e389bfb58be4255b8e5c6b2f997439c@o235174.ingest.sentry.io/1400294",
        release: `nomotex@${process.env.APP_VERSION}`,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
};
