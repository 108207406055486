import React from "react";
import * as R from "ramda";
import cn from "classnames";
import styled from "styled-components";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import Popper from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";
import MenuItemMaterialUI from "@material-ui/core/MenuItem";
import PaperMaterialUi from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { makeStyles } from "@material-ui/styles";
import Link from "./Link";
import { clearDevUrl } from "../../helpers";
const Paper = styled(PaperMaterialUi) `
    && {
        max-height: 300px;
        overflow-y: auto;
    }

    && ul {
        margin: 0;
    }

    @media only screen and (max-width: 768px) {
        && {
            max-height: 100px;
        }
    }
`;
const MenuItem = styled(MenuItemMaterialUI) `
    && {
        padding-left: 10px;
    }

    @media only screen and (max-width: 768px) {
        max-height: 16px;
        font-size: 0.1rem;
    }
`;
const MIN_LENGTH = 4;
const inputShort = R.compose(
// eslint-disable-next-line no-underscore-dangle
R.lte(R.__, MIN_LENGTH), R.propOr(0, "length"));
const onSuggestionsFetchRequested = ({ value, setLoading, loadItems, getInput, setSuggestions }) => {
    if (inputShort(value)) {
        setLoading(false);
        setSuggestions({ query: "", items: [] });
        return;
    }
    setLoading(true);
    loadItems({
        value,
        callback: ({ items, query }) => {
            setLoading(false);
            if (query !== getInput()) {
                return;
            }
            setSuggestions({ items, query });
        },
    });
};
const renderSuggestion = (suggestion, { query, isHighlighted }, handleItemClick, titlePropertyName) => {
    const text = suggestion[titlePropertyName];
    const matches = match(text, query);
    const parts = parse(text, matches);
    const menuItem = (React.createElement(MenuItem, { selected: isHighlighted, onClick: () => handleItemClick({ suggestion }), disableGutters: true },
        React.createElement("div", null, parts.map((part, index) => part.highlight ? (React.createElement("span", { key: String(index), style: { fontWeight: 600 } }, part.text)) : (React.createElement("strong", { key: String(index), style: { fontWeight: 300 } }, part.text))))));
    return suggestion.url ? (React.createElement(Link, { target: "_blank", to: clearDevUrl(suggestion.url) }, menuItem)) : (menuItem);
};
const useStyles = makeStyles({
    loader: {
        width: "100%",
        height: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
    },
    suggestionsContainer: {
        position: "relative",
    },
    suggestionsList: {
        margin: "0 !important",
        padding: 0,
        listStyleType: "none",
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 1,
        left: 0,
        right: 0,
        margin: "5px 0 0 0 !important",
    },
    paper: {
        borderRadius: "4px",
        margin: "10px 0",
    },
    message: {
        padding: "10px 15px",
    },
});
const SearchBar = React.forwardRef(({ loadItems, label, placeholder, handleItemClick = () => { }, getSuggestionValue, className = "", titlePropertyName, inputClasses = {}, inputLabelClasses = {}, InputContainer = React.Fragment, queryName = "q", }, forwardRef) => {
    const [loading, setLoading] = React.useState(false);
    const [focused, setFocused] = React.useState(false);
    const inputRef = React.useRef();
    const paperRef = React.useRef();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    const input = query[queryName] || "";
    const [suggestions, setSuggestions] = React.useState({ query: "", items: [] });
    return (React.createElement(ClickAwayListener, { onClickAway: () => setFocused(false) },
        React.createElement(Autosuggest, { alwaysRenderSuggestions: true, suggestions: suggestions.items, renderInputComponent: (inputProps) => {
                const { ref, ...rest } = inputProps;
                return (React.createElement(InputContainer, null,
                    React.createElement(TextField, { label: label, fullWidth: true, variant: "outlined", InputProps: {
                            inputRef: (node) => {
                                inputRef.current = node;
                                ref(node);
                                if (typeof forwardRef === "function") {
                                    forwardRef(node);
                                }
                            },
                            classes: inputClasses,
                            ...rest,
                        }, InputLabelProps: {
                            classes: inputLabelClasses,
                        } })));
            }, onSuggestionsFetchRequested: (params) => {
                if (params.value !== suggestions.query && params.reason !== "suggestion-selected") {
                    onSuggestionsFetchRequested({
                        value: params.value,
                        loadItems,
                        setLoading,
                        setSuggestions,
                        // Возвращает текущее значение заголовка.
                        // Берём значение прямо из DOM дерева,
                        // потому что по непонятным причинам, оно там появляется раньше,
                        // чем в состоянии компонента (input от React.useState).
                        getInput: () => (inputRef.current ? inputRef.current.value : ""),
                    });
                }
            }, onSuggestionsClearRequested: () => { }, getSuggestionValue: getSuggestionValue, renderSuggestion: (suggestion, params) => renderSuggestion(suggestion, params, handleItemClick, titlePropertyName), onSuggestionSelected: () => { }, inputProps: {
                value: input,
                onChange: (_, { newValue, method }) => {
                    if (method !== "click") {
                        history.push({
                            pathname: location.pathname,
                            search: qs.stringify({
                                ...query,
                                [queryName]: newValue,
                            }),
                        });
                    }
                },
                onFocus: () => setFocused(true),
                placeholder,
            }, theme: {
                container: cn(className, classes.suggestionsContainer),
                suggestionsList: classes.suggestionsList,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
            }, renderSuggestionsContainer: (options) => {
                const getChildren = () => {
                    if (loading) {
                        return (React.createElement("div", { className: classes.loader },
                            React.createElement(CircularProgress, { size: 50 })));
                    }
                    if (options.children) {
                        return options.children;
                    }
                    if (inputShort(input)) {
                        return React.createElement(Typography, { className: classes.message },
                            "\u0417\u0430\u043F\u0440\u043E\u0441 \u0434\u043E\u043B\u0436\u0435\u043D \u0431\u044B\u0442\u044C \u0434\u043B\u0438\u043D\u0435\u0435 ",
                            MIN_LENGTH,
                            "-\u0445 \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432");
                    }
                    return React.createElement(Typography, { className: classes.message }, "\u041F\u043E \u0432\u0430\u0448\u0435\u043C \u0437\u0430\u043F\u0440\u043E\u0441\u0443 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D \u043D\u0438 \u043E\u0434\u0438\u043D \u043A\u0432\u0430\u043D\u0442");
                };
                return (React.createElement(Popper, { anchorEl: inputRef.current, open: focused, placement: "bottom-start", popperOptions: {
                        eventsEnabled: false,
                    }, disablePortal: true, style: {
                        zIndex: 99999,
                    } },
                    React.createElement(Paper, Object.assign({ square: true }, options.containerProps, { ref: (node) => {
                            paperRef.current = node;
                            options.containerProps.ref(node);
                        }, style: {
                            width: inputRef.current ? inputRef.current.clientWidth : "auto",
                        }, classes: {
                            root: classes.paper,
                        }, component: "div", tabIndex: 0 }), getChildren())));
            } })));
});
export default SearchBar;
