import { matchPath } from "react-router-dom";
import memoizeOne from "memoize-one";
import routes from "./routes";
export const getRouteProps = memoizeOne((pathname) => {
    const routeIdx = routes.findIndex(({ layoutProps, component, ...props }) => matchPath(pathname, props));
    if (routeIdx === -1) {
        return {
            layoutProps: {},
            component: (() => null),
        };
    }
    return routes[routeIdx];
});
