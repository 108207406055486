/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Link as Route } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "50px",
    },
    wrapper: {
        display: "flex",
        padding: "10px 15px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    generation: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
    paragraph: {
        textIndent: "35px",
    },
    about: {
        textAlign: "center",
    },
    other: {
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
    otherLink: {
        marginLeft: "10px",
    },
}));
const Footer = React.memo(() => {
    const classes = useStyles();
    return (React.createElement(Container, { className: classes.container },
        React.createElement(Divider, null),
        React.createElement("div", { className: classes.wrapper },
            React.createElement(Typography, { variant: "body2", color: "textSecondary", className: classes.generation, gutterBottom: true },
                "\u00A9 2017-",
                new Date().getFullYear(),
                " \u041C\u0413\u0422\u0423 \u0438\u043C. \u041D.\u042D. \u0411\u0430\u0443\u043C\u0430\u043D\u0430, \u043A\u0430\u0444\u0435\u0434\u0440\u0430 \u0424\u041D-11"),
            React.createElement("div", { className: classes.other },
                React.createElement(Link, { to: "/about", component: Route }, "\u041E \u043D\u043E\u043C\u043E\u0442\u0435\u0445\u0435"),
                React.createElement(Link, { className: classes.otherLink, href: "https://open.bmstu.ru/" }, "\u041E\u0442\u043A\u0440\u044B\u0442\u044B\u0439 \u041C\u0413\u0422\u0423"),
                React.createElement(Link, { className: classes.otherLink, href: "http://online.bmstu.ru/" }, "\u041C\u0413\u0422\u0423 \u041E\u043D\u043B\u0430\u0439\u043D")))));
});
export default Footer;
