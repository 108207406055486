import { SHOW_MENU, HIDE_MENU } from "../store/menu/types";
import { localStorageId } from "../store/menu/constants";
export const showMenu = () => {
    localStorage.setItem(localStorageId, JSON.stringify(true));
    return {
        type: SHOW_MENU,
    };
};
export const hideMenu = () => {
    localStorage.setItem(localStorageId, JSON.stringify(false));
    return {
        type: HIDE_MENU,
    };
};
