import { createSlice } from "@reduxjs/toolkit";
import { map } from "ramda";
import axios from "client/features/request";
const initialState = {
    status: "loading",
    list: [],
};
const participantsFilters = createSlice({
    name: "participantsFilters",
    initialState,
    reducers: {
        participantsFiltersStartLoading: (state) => ({
            ...state,
            status: "loading",
            list: [],
        }),
        participantsFiltersLoaded: (state, action) => ({
            ...state,
            status: "loaded",
            list: action.payload.filters,
        }),
    },
});
export const { participantsFiltersStartLoading, participantsFiltersLoaded } = participantsFilters.actions;
export const loadParticipantsFilters = () => async (dispatch) => {
    dispatch(participantsFiltersStartLoading());
    const response = await axios.get("/api/profile/filters/");
    if (response.data.status === "error") {
        throw response.data.error;
    }
    const { payload } = response.data;
    const filters = map((filter) => {
        const map = {
            all: "Все",
            group: "Группы",
            teaching: "Курсы",
            student: "Студенты",
            staff: "Сотрудники",
            lecturer: "Преподаватели",
        };
        return {
            ...filter,
            id: `${filter.type}-${filter.id}`,
            groupName: map[filter.type],
        };
    }, payload.filters);
    dispatch(participantsFiltersLoaded({ filters }));
};
export default participantsFilters.reducer;
