import React from "react";
import { useDispatch } from "react-redux";
import * as R from "ramda";
import { Formik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import { setLectureRecordData } from "../../../actions/lectureRecord";
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: "15px 0",
    },
    paper: {
        width: "450px",
        height: "100%",
        padding: "15px 25px",
        overflowY: "auto",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            maxWidth: "90vw",
        },
    },
    submitButton: {
        marginBottom: "15px",
    },
}));
const TeachingForm = (props) => {
    const { show, groups, handleClose, setActive } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    return (React.createElement(Formik, { initialValues: {
            groupId: "",
            teachingId: "",
        }, validationSchema: yup.object().shape({
            groupId: yup.string().required("Выберите группу"),
            teachingId: yup.string().required("Выберите преподавание"),
        }), isInitialValid: false, onSubmit: (values, actions) => {
            dispatch(setLectureRecordData(values));
            actions.setSubmitting(false);
            handleClose();
            setActive();
        }, render: ({ values, touched, errors, handleChange, handleSubmit, handleBlur, isSubmitting, isValid }) => {
            const selectedGroup = R.find(R.propEq("id", values.groupId))(groups);
            const teachings = R.pathOr([], ["teachings"], selectedGroup);
            return (React.createElement(Drawer, { open: show, onClose: handleClose, variant: "temporary", anchor: "right" },
                React.createElement(Paper, { className: classes.paper },
                    React.createElement(FormControl, { fullWidth: true, className: classes.formControl },
                        React.createElement(FormLabel, { required: true }, "\u0413\u0440\u0443\u043F\u043F\u0430"),
                        React.createElement(Select, { name: "groupId", value: values.groupId, error: Boolean(touched.groupId && errors.groupId), onChange: handleChange, onBlur: handleBlur }, groups.map((group) => (React.createElement(MenuItem, { key: group.id, value: group.id }, group.title))))),
                    React.createElement(FormControl, { fullWidth: true, className: classes.formControl },
                        React.createElement(FormLabel, { required: true }, "\u041F\u0440\u0435\u0434\u043C\u0435\u0442"),
                        React.createElement(Select, { name: "teachingId", value: values.teachingId, error: Boolean(touched.teachingId && errors.teachingId), onChange: handleChange, onBlur: handleBlur }, teachings.map((teaching) => (React.createElement(MenuItem, { value: teaching.pk, key: teaching.pk }, teaching.course.title))))),
                    React.createElement(FormControl, null,
                        React.createElement(Button, { size: "medium", variant: "contained", color: "primary", disabled: isSubmitting || !isValid, onClick: () => handleSubmit(), className: classes.submitButton }, "\u0417\u0430\u043F\u043E\u043C\u0438\u043D\u0430\u0442\u044C \u043F\u0440\u043E\u0439\u0434\u0435\u043D\u043D\u044B\u0435 \u043A\u0432\u0430\u043D\u0442\u044B")))));
        } }));
};
export default TeachingForm;
