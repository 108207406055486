import React from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import FixedWidth from "@material-ui/core/Container";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import axios from "client/features/request";
import { loadUserData, setUserDataLoading } from "client/actions/user";
import { hideMenu, showMenu } from "client/actions/menu";
import { clearLectureRecordData } from "client/actions/lectureRecord";
import { debouncedLoadElements } from "client/components/Thesaurus/utils";
import SearchBar from "client/components/SearchBar";
import LogoImg from "client/components/Layout/logo.ico";
import Tab from "./Tab";
import Name from "./Name";
import { MENU_WIDTH } from "../utils";
const useStyles = makeStyles((theme) => ({
    toolbar: {
        minHeight: "64px",
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    appBar: {
        display: (p) => (p.isPresentation ? "none" : "flex"),
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down("xs")]: {
            display: "flex !important",
        },
    },
    appBarStage: {
        background: theme.palette.error.light,
    },
    appBarShift: {
        width: `calc(100% - ${MENU_WIDTH}px)`,
        marginLeft: MENU_WIDTH,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    logo: {
        width: "35px",
        height: "auto",
        marginRight: "10px",
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            padding: "0 16px 0 0",
        },
    },
    headerMainGroup: {
        display: "flex",
        width: "100%",
        maxWidth: "580px",
        justifyContent: "space-around",
        marginRight: "60px",
        [theme.breakpoints.down("md")]: {
            maxWidth: "unset",
            justifyContent: "flex-end",
        },
        [theme.breakpoints.down("sm")]: {
            marginRight: 0,
        },
    },
    autosuggest: {
        flex: "1",
        marginRight: "25px",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    input: {
        padding: "15px 14px",
    },
    label: {
        transform: "translate(14px, 17px) scale(1)",
    },
}));
const searchBarTheme = createMuiTheme({
    palette: {
        type: "dark",
        text: {
            primary: "rgb(240, 240, 255)",
        },
        primary: {
            main: "rgb(240, 240, 255)",
        },
    },
});
const InputContainer = ({ children }) => React.createElement(ThemeProvider, { theme: searchBarTheme }, children);
const Header = ({ user, menu, isMobile, history, setShowAuthDialog }) => {
    const dispatch = useDispatch();
    const classes = useStyles({
        isPresentation: user.theme.isPresentation,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(AppBar, { position: "fixed", className: cn({
                [classes.appBar]: true,
                [classes.appBarShift]: menu.show && !isMobile,
                [classes.appBarStage]: NODE_ENV === "stage",
            }) },
            React.createElement(Toolbar, { disableGutters: true, className: classes.toolbar },
                menu.show ? (React.createElement(IconButton, { onClick: () => dispatch(hideMenu()), className: classes.menuButton },
                    React.createElement(ChevronLeftIcon, { htmlColor: "white" }))) : (React.createElement(IconButton, { color: "inherit", "aria-label": "open-menu", onClick: () => dispatch(showMenu()), className: classes.menuButton },
                    React.createElement(MenuIcon, null))),
                React.createElement(FixedWidth, { className: classes.headerContainer },
                    React.createElement(Tab, { href: "/", title: "Nomotex", bigFont: true, icon: React.createElement("img", { className: classes.logo, src: LogoImg, alt: "\u0413\u043B\u0430\u0432\u043D\u0430\u044F" }) }),
                    user.isAuthenticated ? (React.createElement("div", { className: classes.headerMainGroup },
                        React.createElement(SearchBar, { loadItems: debouncedLoadElements, label: "\u041F\u043E\u0438\u0441\u043A \u043F\u043E \u0441\u043E\u0434\u0435\u0440\u0436\u0438\u043C\u043E\u043C\u0443 \u043A\u0432\u0430\u043D\u0442\u0430", placeholder: "\u041D\u0430\u0447\u043D\u0438\u0442\u0435 \u0432\u0432\u043E\u0434\u0438\u0442\u044C \u0437\u0430\u043F\u0440\u043E\u0441...", titlePropertyName: "name", getSuggestionValue: (suggestion) => suggestion.name, className: classes.autosuggest, inputClasses: {
                                input: classes.input,
                            }, inputLabelClasses: {
                                root: classes.label,
                            }, InputContainer: InputContainer }),
                        React.createElement(Name, null),
                        React.createElement(Tab, { title: "\u0412\u044B\u0439\u0442\u0438", onClick: async () => {
                                dispatch(clearLectureRecordData());
                                dispatch(setUserDataLoading(true));
                                await axios.get("/api/profile/logout/", {
                                    withCredentials: true,
                                });
                                history.push("/");
                                dispatch(hideMenu());
                                dispatch(loadUserData());
                            } }))) : (React.createElement(Tab, { title: "\u0412\u043E\u0439\u0442\u0438", onClick: () => setShowAuthDialog(true) })))))));
};
export default Header;
