import { faSun, faGraduationCap, faLandmark, faLeaf, faListUl, faPencilAlt, faUserAlt, faDatabase, faUsersCog, faQuestionCircle, } from "@fortawesome/free-solid-svg-icons";
import axios from "../../features/request";
import { actionUpdateGraphsState, loadUpdateGraphState } from "../../actions/user";
export const MENU_WIDTH = 320;
export const getMainMenusData = ({ user }) => [
    {
        title: "Личный кабинет",
        icon: faUserAlt,
        iconColor: "#928383",
        links: [
            {
                href: "/profile/",
                title: "Успеваемость",
            },
            {
                href: "/profile/notifications/",
                title: "Объявления",
            },
            {
                href: "/profile/conferences/",
                title: "Конференции",
            },
            user.profile.position !== "student" && {
                href: "/profile/tickets/",
                title: "Билеты",
            },
            user.profile.position !== "student" && {
                href: "/profile/proctoring/",
                title: "Прокторинг",
            },
            user.profile.position === "student" && {
                href: "/profile/tasks/",
                title: "Текущие задания",
            },
            {
                href: "/profile/common/",
                title: "Учётные данные",
            },
            user.profile.position !== "student" && {
                href: "/profile/add-student/",
                title: "Добавить студента",
            },
            user.profile.position !== "student" && {
                href: "/profile/completed-tasks/",
                title: "Мои выполненные задания",
            },
        ].filter(Boolean),
        show: () => user.isAuthenticated,
    },
    {
        title: "База знаний",
        icon: faSun,
        iconColor: "#cccc0b",
        links: [
            {
                href: "/knowledge-base/",
                title: "Нейросетевая структура знаний (Дерево)",
            },
            {
                href: "/circle-knowledge-base/",
                title: "Нейросетевая структура знаний (Круг)",
            },
        ],
    },
    {
        title: "База нейросетевых ОП",
        icon: faGraduationCap,
        href: "/educational-direction/tree/",
        iconColor: "#c78f21",
    },
    {
        title: "Реализация нейросетевых ОП",
        href: "/educational-direction/gep/",
        icon: faLandmark,
        iconColor: "#08b0bb",
    },
    {
        title: "Конструирование нейросетевых ОП",
        href: "/educational-direction/add/",
        icon: faLeaf,
        iconColor: "#01b701",
        position: "admin",
    },
    {
        title: "Тезаурус",
        href: "/thesaurus/",
        icon: faListUl,
        iconColor: "#d40e0e",
    },
    {
        title: "Визуальная математика",
        href: "/visualization/",
        icon: faPencilAlt,
        iconColor: "#f1008d",
        includesCompare: true,
    },
    {
        title: "Администрирование",
        href: "/ammf/",
        icon: faUsersCog,
        iconColor: "#000",
        position: "admin",
        rawLink: true,
    },
    {
        title: "Обновить графы",
        href: "/update/",
        icon: faDatabase,
        iconColor: "#7e69e8",
        position: "admin",
        getViewProps: () => ({
            disabled: user.updateGraph.state === "UPDATING",
        }),
        onClick: async ({ event, dispatch }) => {
            event.preventDefault();
            if (user.updateGraph.state === "UPDATING") {
                return;
            }
            dispatch(actionUpdateGraphsState("UPDATING"));
            await axios.get("/api/graphs/update/");
            dispatch(actionUpdateGraphsState((await loadUpdateGraphState()).state));
        },
        getTooltipText: () => (user.updateGraph.state === "UPDATING" ? "Графы в данный момент обновляются" : "Все графы обновлены"),
    },
    {
        href: "/faq/",
        title: "Инструкции",
        icon: faQuestionCircle,
        iconColor: "#5170ed",
        includesCompare: true,
        show: () => user.isAuthenticated,
    },
].filter(({ position, show }) => {
    if (show) {
        return show();
    }
    return (
    // Если position не указана, то можно всем.
    // В противном случае проверяем, что position user'а и разрешённая совпадает.
    !position || position === user.profile.position);
});
