/* eslint-disable import/prefer-default-export */
export const getFormData = ({ files, ...rest }) => {
    const formData = new FormData();
    Object.entries({
        ...rest,
        location: window.location.href,
    }).forEach(([key, value]) => formData.append(key, value));
    files.forEach(({ source: file }) => formData.append(`file-${file.name}`, file, file.name));
    return formData;
};
