import MobileDetect from "mobile-detect";
import { SHOW_MENU, HIDE_MENU } from "../store/menu/types";
import { localStorageId } from "../store/menu/constants";
const md = new MobileDetect(window.navigator.userAgent);
const getInitialPanelState = () => {
    const { pathname } = window.location;
    if (pathname.includes("confirm-reset-password") || pathname.includes("module-results") || md.mobile() || pathname === "/") {
        return false;
    }
    try {
        const show = localStorage.getItem(localStorageId) || "false";
        return JSON.parse(show);
    }
    catch (_a) {
        return false;
    }
};
const initialState = {
    show: getInitialPanelState(),
    items: [],
};
const menu = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MENU:
            return {
                ...state,
                show: true,
            };
        case HIDE_MENU:
            return {
                ...state,
                show: false,
            };
        default:
            return state;
    }
};
export default menu;
