import React from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles(() => ({
    link: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        height: "64px",
        lineHeight: "1.3rem",
        cursor: "pointer",
        outline: "none",
        textDecoration: "none",
        "&:hover": {
            color: "#f0f0ff",
            textDecoration: "none",
        },
        "&:active": {
            textDecoration: "none",
        },
        "&:focus": {
            textDecoration: "none",
        },
        "&::selection": {
            background: "transparent",
        },
    },
}));
/**
 * Если меню является ссылкой, то возвращает тег <a>.
 * В противном случае, тег <div>.
 */
const TabContainer = ({ href = "", className = "", onClick = () => { }, children }) => {
    const s = useStyles();
    // Если передали href в компонент,
    // значит у данного компонента нет подменю и это просто ссылка.
    if (href) {
        return (React.createElement(Link, { className: cn(s.link, className), to: href }, children));
    }
    return (React.createElement(Button, { className: className, onClick: onClick, role: "button", onKeyDown: () => { }, tabIndex: 0 }, children));
};
export default TabContainer;
