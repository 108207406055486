import { createAction } from "@reduxjs/toolkit";
import axios from "../features/request";
export const setLoading = createAction("LOADING_TICKETS_TREE");
export const setTree = createAction("SET_TICKETS_TREE");
export const loadTicketsTree = (params) => async (dispatch) => {
    dispatch(setLoading({ loading: true }));
    const { data } = await axios.get("/api/educational-testing/educational-modules/generate-tickets-form-data/", { params });
    if (data.status === "error") {
        throw data.error;
    }
    dispatch(setTree({ tickets: data.payload.tickets }));
    dispatch(setLoading({ loading: false }));
};
