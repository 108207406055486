import "whatwg-fetch";
import "@babel/polyfill";
import "typeface-roboto";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import store from "./store";

import App from "./components/App";
import theme from "./components/theme/index";

import { reactRoot } from "./root";
import { initSentry } from "./features/sentry";
import { initServiceWorkers } from "./pwa";

moment.locale("ru");

initSentry();
initServiceWorkers();

const Root = () => (
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <App />
        </Provider>
    </ThemeProvider>
);

ReactDOM.render(<Root />, reactRoot);
