export const showSnackbar = ({ message, anchor = {
    horizontal: "right",
    vertical: "top",
}, type = "default", autoHideDuration, }) => ({
    type: "SHOW_SNACKBAR" /* SHOW */,
    payload: {
        message,
        anchor,
        type,
        autoHideDuration,
    },
});
export const hideSnackbar = () => async (dispatch) => {
    dispatch({
        type: "HIDE_SNACKBAR_CONTENT" /* HIDE_SNACKBAR_CONTENT */,
    });
    setTimeout(() => {
        dispatch({
            type: "HIDE_SNACKBAR" /* HIDE */,
        });
    }, 1000);
};
