import { LOCAL_STORAGE_KEY } from "../store/lectureRecord/types";
export const setLectureRecordData = (payload) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(payload));
    return {
        type: "SET_DATA" /* SET_DATA */,
        payload,
    };
};
export const clearLectureRecordData = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    return {
        type: "CLEAR_DATA" /* CLEAR_DATA */,
    };
};
export const setTeaching = (payload) => ({
    type: "SET_TEACHING" /* SET_TEACHING */,
    payload,
});
