/* eslint-disable import/prefer-default-export */
import * as R from "ramda";
import qs from "qs";
import axios from "../../../features/request";
import { getStudyGroups } from "../../StudyGroups/utils";
const loadTeachings = async (groupId, query) => {
    const { data } = await axios.get(`/api/profile/study-groups/${groupId}/teachings/${query}`);
    if (data.status === "error") {
        throw data.error;
    }
    return data.payload.teachings;
};
export const loadStudyGroups = async () => {
    const groups = await getStudyGroups();
    const teachings = await Promise.all(groups.map((group) => loadTeachings(group.pk, qs.stringify({ "filter-for-admin": true, "courses-only": true }, { addQueryPrefix: true }))));
    return R.zip(groups, teachings).map(([group, t]) => R.mergeRight(group, t));
};
