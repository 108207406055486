import React from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import axios from "client/features/request";
import sleep from "client/helpers/sleep";
import { getCSRFToken } from "client/helpers";
import { student, authenticated } from "client/selectors";
import Link from "client/components/Link";
import FileUploader from "client/components/FileUploader";
import { getFormData } from "./helpers";
const sendFeedback = async (values, actions, handleClose, showDialog) => {
    await sleep(3000);
    const data = getFormData(values);
    try {
        await axios.post("/api/feedback/", data, {
            headers: {
                "X-CSRFToken": await getCSRFToken(),
            },
        });
        showDialog(`🎉 Спасибо за отзыв! 🎉`);
        actions.resetForm();
    }
    catch (err) {
        showDialog("Извините, не удалось отправить отзыв, пожалуйста, поробуйте в другое время. Связаться с поддержкой Nomotex можно по почте adm.nomotex@yandex.ru");
    }
    actions.setSubmitting(false);
    handleClose();
};
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: "15px 0",
    },
    paper: {
        width: "450px",
        height: "100%",
        padding: "15px 25px",
        overflowY: "auto",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            maxWidth: "90vw",
        },
    },
    submitButton: {
        marginBottom: "15px",
        minWidth: "180px",
    },
    dialogContent: {
        padding: "15px 25px 0 25px",
    },
    warning: {
        marginBottom: theme.spacing(2),
    },
}));
const FeedbackForm = (props) => {
    const { show, handleClose } = props;
    const classes = useStyles();
    const isStudent = useSelector(student);
    const isAuthenticated = useSelector(authenticated);
    const [showDialog, setShow] = React.useState(false);
    const [dialog, setDialog] = React.useState("");
    const handleDialogClose = React.useCallback(() => setShow(false), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Formik, { initialValues: {
                type: "enhancement",
                title: "",
                description: "",
                email: "",
                files: [],
            }, validationSchema: yup.object().shape({
                type: yup.mixed().oneOf(["enhancement", "help", "bug"]),
                title: yup.string().required(),
                description: yup.string().required(),
                email: yup.string().email(),
            }), onSubmit: (values, actions) => sendFeedback(values, actions, handleClose, (content) => {
                setShow(true);
                setDialog(content);
            }), validateOnMount: true }, ({ values, touched, errors, handleChange, handleSubmit, handleBlur, setFieldValue, isSubmitting, isValid }) => {
            return (React.createElement(Drawer, { open: show, onClose: handleClose, variant: "temporary", anchor: "right" },
                React.createElement(Paper, { className: classes.paper },
                    React.createElement(FormControl, { fullWidth: true, className: classes.formControl },
                        React.createElement(FormLabel, { required: true }, "\u042F \u0445\u043E\u0447\u0443..."),
                        React.createElement(RadioGroup, { value: values.type, name: "type", onChange: handleChange, onBlur: handleBlur },
                            React.createElement(FormControlLabel, { value: "enhancement", control: React.createElement(Radio, null), label: "\u041F\u0440\u0435\u0434\u043B\u043E\u0436\u0438\u0442\u044C \u0443\u043B\u0443\u0447\u0448\u0435\u043D\u0438\u0435" }),
                            React.createElement(FormControlLabel, { value: "help", control: React.createElement(Radio, null), label: "\u041F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u043F\u043E\u043C\u043E\u0449\u044C" }),
                            React.createElement(FormControlLabel, { value: "bug", control: React.createElement(Radio, null), label: "\u0421\u043E\u043E\u0431\u0449\u0438\u0442\u044C \u043E\u0431 \u043E\u0448\u0438\u0431\u043A\u0435" }))),
                    React.createElement(FormControl, { fullWidth: true, className: classes.formControl },
                        React.createElement(FormLabel, { required: true, error: Boolean(touched.title && errors.title) }, "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A"),
                        React.createElement(Input, { required: true, name: "title", value: values.title, onChange: handleChange, onBlur: handleBlur, error: Boolean(touched.title && errors.title) })),
                    React.createElement(FormControl, { fullWidth: true, className: classes.formControl },
                        React.createElement(FormLabel, { required: true, error: Boolean(touched.description && errors.description) }, "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435"),
                        React.createElement(Input, { required: true, name: "description", multiline: true, fullWidth: true, value: values.description, onChange: handleChange, onBlur: handleBlur, rows: 5, placeholder: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435", error: Boolean(touched.description && errors.description) })),
                    React.createElement(FormControl, { fullWidth: true, className: classes.formControl },
                        React.createElement(FormLabel, { error: Boolean(touched.email && errors.email) }, "\u0415\u0441\u043B\u0438 \u0432\u044B \u0445\u043E\u0442\u0438\u0442\u0435, \u0447\u0442\u043E\u0431\u044B \u043C\u044B \u0441 \u0412\u0430\u043C\u0438 \u0441\u0432\u044F\u0437\u0430\u043B\u0438\u0441\u044C, \u0443\u043A\u0430\u0436\u0438\u0442\u0435 e-mail"),
                        React.createElement(Input, { name: "email", value: values.email, onChange: handleChange, onBlur: handleBlur, error: Boolean(touched.email && errors.email) })),
                    React.createElement(FormControl, { fullWidth: true, className: classes.formControl },
                        React.createElement(FormLabel, null, "\u041F\u0440\u0438 \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E\u0441\u0442\u0438 \u043F\u0440\u0438\u043A\u0440\u0435\u043F\u0438\u0442\u0435 \u0444\u0430\u0439\u043B\u044B"),
                        React.createElement(FileUploader, { name: "files", files: values.files, setFieldValue: setFieldValue })),
                    isStudent && isAuthenticated && (React.createElement(Typography, { className: classes.warning, variant: "body2" },
                        "\u041E\u0431\u0440\u0430\u0442\u0438\u0442\u0435, \u043F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0432\u043D\u0438\u043C\u0430\u043D\u0438\u0435 \u043D\u0430",
                        " ",
                        React.createElement(Link, { href: "/faq/support/", onClick: () => handleClose() }, "\u043F\u0440\u0430\u0432\u0438\u043B\u0430 \u043E\u0431\u0440\u0430\u0449\u0435\u043D\u0438\u044F \u0432 \u0442\u0435\u0445\u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443"))),
                    React.createElement(FormControl, null,
                        React.createElement(Button, { size: "medium", variant: "contained", color: "primary", disabled: isSubmitting || !isValid, onClick: () => handleSubmit(), className: classes.submitButton }, isSubmitting ? React.createElement(CircularProgress, { size: 30 }) : "Отправить отзыв")))));
        }),
        React.createElement(Dialog, { open: showDialog, onClose: handleDialogClose },
            React.createElement(DialogContent, { className: classes.dialogContent },
                React.createElement(DialogContentText, { color: "textPrimary" }, dialog)),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: handleDialogClose, variant: "outlined", color: "primary" }, "OK")))));
};
export default FeedbackForm;
