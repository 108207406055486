import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    show: false,
};
const globalLoader = createSlice({
    name: "globalLoader",
    initialState,
    reducers: {
        showGlobalLoader: () => ({
            show: true,
        }),
        hideGlobalLoader: () => ({
            show: false,
        }),
    },
});
export const { showGlobalLoader, hideGlobalLoader } = globalLoader.actions;
export default globalLoader.reducer;
