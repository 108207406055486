import * as React from "react";
import FlagIcon from "@material-ui/icons/Flag";
import TeachingForm from "./TeachingForm";
import Fab from "../Fab";
import { loadStudyGroups } from "./helpers";
const reducer = (state, action) => {
    switch (action.type) {
        case 0 /* SET_SHOW */:
            return {
                ...state,
                show: action.payload,
            };
        case 1 /* SET_GROUPS */:
            return {
                ...state,
                groups: action.payload,
            };
        default:
            return state;
    }
};
const LectureRecording = (props) => {
    const [state, dispatch] = React.useReducer(reducer, {
        show: false,
        groups: [],
    });
    React.useEffect(() => {
        loadStudyGroups().then((groups) => dispatch({
            type: 1 /* SET_GROUPS */,
            payload: groups,
        }));
    }, []);
    const { className, getTransform, setActive } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(Fab, { className: className, getTransform: getTransform, onClick: () => dispatch({
                type: 0 /* SET_SHOW */,
                payload: true,
            }) },
            React.createElement(FlagIcon, { fontSize: "small" })),
        React.createElement(TeachingForm, { show: state.show, groups: state.groups, handleClose: () => dispatch({
                type: 0 /* SET_SHOW */,
                payload: false,
            }), setActive: setActive })));
};
export default LectureRecording;
