import * as React from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 99999,
        background: "rgba(0, 0, 0, 0.8)",
    },
    loader: {
        color: "#1a90ff",
        animationDuration: "550ms",
    },
    circle: {
        strokeLinecap: "round",
    },
}));
const GlobalLoader = (props) => {
    const s = useStyles(props);
    return (React.createElement("div", { className: s.root },
        React.createElement(CircularProgress, { className: s.loader, size: 60, thickness: 3, variant: "indeterminate", disableShrink: true, classes: {
                circle: s.circle,
            } })));
};
export default GlobalLoader;
