import { createSlice } from "@reduxjs/toolkit";
import axios from "client/features/request";
const initialState = {
    list: [],
    status: "loading",
};
const conferences = createSlice({
    name: "conferences",
    initialState,
    reducers: {
        startLoading: (state) => ({
            ...state,
            status: "loading",
        }),
        loaded: (state, action) => ({
            ...state,
            list: action.payload.conferences,
            status: "loaded",
        }),
    },
});
const { startLoading, loaded } = conferences.actions;
export const loadConferences = () => async (dispatch) => {
    dispatch(startLoading());
    const { data: { payload: { availableConferences, endedConferences }, }, } = await axios.get("/api/conferences/");
    dispatch(loaded({
        conferences: [...availableConferences, ...endedConferences],
    }));
};
export default conferences.reducer;
