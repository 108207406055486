import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import memoizeOne from "memoize-one";
import cn from "classnames";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import FabBase from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { makeStyles } from "@material-ui/styles";
import { useSpring, animated } from "react-spring";
import axios from "../../features/request";
import FeedbackAction from "./FeedbackAction";
import LectureRecording from "./LectureRecording";
import { useContainerStyles, useFadeTransition } from "./common";
import { clearLectureRecordData, setTeaching } from "../../actions/lectureRecord";
import { getRecordingStopTitle } from "./utils";
const Fab = animated(FabBase);
const useStyles = makeStyles((theme) => ({
    close: {
        backgroundColor: (state) => {
            if (state.open || state.active) {
                return theme.palette.error.light;
            }
            return theme.palette.primary.light;
        },
        "&:hover": {
            backgroundColor: (state) => {
                if ((state.open && state.hovered) || state.active) {
                    return theme.palette.error.main;
                }
                return theme.palette.primary.main;
            },
        },
    },
}));
const getTransform = memoizeOne((state) => {
    const { open, hovered } = state;
    if (open) {
        return "scale(1.2) rotate(45)";
    }
    if (hovered) {
        return "scale(1.2) rotate(0deg)";
    }
    return "scale(1) rotate(0deg)";
});
const TooltipContainer = (props) => {
    const { text, children } = props;
    if (!text) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement(Tooltip, { title: text, placement: "left" }, children));
};
const reducer = (state, action) => {
    switch (action.type) {
        case 0 /* SET_OPEN */:
            return {
                ...state,
                open: action.payload,
            };
        case 1 /* SET_ACTIVE */:
            return {
                ...state,
                active: action.payload,
            };
        case 2 /* SET_HOVERED */:
            return {
                ...state,
                hovered: action.payload,
            };
        default:
            return state;
    }
};
const StaffActions = () => {
    const lectureRecord = useSelector((state) => state.lectureRecord);
    const [state, dispatch] = React.useReducer(reducer, {
        open: false,
        active: false,
        hovered: false,
    }, (initial) => {
        if (!lectureRecord.groupId || !lectureRecord.teachingId) {
            return initial;
        }
        return {
            ...initial,
            active: true,
        };
    });
    const fab = useSpring({
        transform: getTransform(state),
        from: { transform: "scale(1) rotate(0deg)" },
    });
    const handleActive = React.useCallback(() => dispatch({
        type: 1 /* SET_ACTIVE */,
        payload: true,
    }), []);
    const handleFocus = React.useCallback(() => dispatch({
        type: 2 /* SET_HOVERED */,
        payload: true,
    }), []);
    const handleBlur = React.useCallback(() => dispatch({
        type: 2 /* SET_HOVERED */,
        payload: false,
    }), []);
    const storeDispatch = useDispatch();
    React.useEffect(() => {
        if (!lectureRecord.teachingId) {
            return;
        }
        axios
            .get(`/api/profile/teachings/${lectureRecord.teachingId}/`)
            .then((response) => storeDispatch(setTeaching(response.data.payload)));
    }, [storeDispatch, lectureRecord.teachingId]);
    const handleClick = React.useCallback(() => {
        if (state.active) {
            storeDispatch(clearLectureRecordData());
            return dispatch({
                type: 1 /* SET_ACTIVE */,
                payload: false,
            });
        }
        return dispatch({
            type: 0 /* SET_OPEN */,
            payload: !state.open,
        });
    }, [state.open, state.active, storeDispatch]);
    const commonClasses = useContainerStyles(state);
    const classes = useStyles(state);
    const getFeedbackTransform = useFadeTransition({
        index: 1,
        open: state.open,
    });
    const getLectureRecordingTransform = useFadeTransition({
        index: 2,
        open: state.open,
    });
    return (React.createElement(ClickAwayListener, { onClickAway: () => {
            if (state.open) {
                handleClick();
            }
        } },
        React.createElement("div", null,
            React.createElement(TooltipContainer, { text: state.active ? (React.createElement(Typography, { variant: "body2", align: "center" }, getRecordingStopTitle(lectureRecord.teaching))) : null },
                React.createElement(Fab, { style: fab, color: "primary", size: "medium", className: cn(commonClasses.container, classes.close), onMouseOver: handleFocus, onMouseOut: handleBlur, onFocus: handleFocus, onBlur: handleBlur, onClick: handleClick }, state.active ? React.createElement(CameraAltIcon, { fontSize: "small" }) : React.createElement(AddIcon, { fontSize: "small" }))),
            React.createElement(FeedbackAction, { className: commonClasses.open, getTransform: getFeedbackTransform }),
            React.createElement(LectureRecording, { className: commonClasses.open, getTransform: getLectureRecordingTransform, setActive: handleActive }))));
};
export default withRouter(StaffActions);
