import debounce from "lodash/debounce";
import axios from "../../features/request";
export const loadElements = async ({ value, callback }) => {
    try {
        const response = await axios.get("/api/elements/search/", {
            params: { q: value },
        });
        const { elements, query } = response.data;
        callback({ items: elements, query });
    }
    catch (_a) {
        callback({ items: [], query: value });
    }
};
export const debouncedLoadElements = debounce(loadElements, 1000);
