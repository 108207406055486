import { createReducer } from "@reduxjs/toolkit";
import { setLoading, setTree } from "../actions/ticketsTree";
const initialState = {
    loading: false,
    tickets: null,
};
const ticketsTree = createReducer(initialState, {
    [setLoading.type]: (state, action) => ({
        ...state,
        loading: action.payload.loading,
    }),
    [setTree.type]: (state, action) => ({
        ...state,
        tickets: action.payload.tickets,
    }),
});
export default ticketsTree;
