/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from "axios";
import Cookies from "js-cookie";
import { pathEq } from "ramda";
import store from "client/store";
import { loadUserData } from "client/actions/user";
import { getCSRFToken } from "client/helpers";
import { baseUrlMiddleware } from "./middlewares";
export const isAuthResponseError = (data) => data.status === "error" && data.payload === "LOGIN_REQUIRED";
const isResponseError = pathEq(["data", "status"], "error");
const instance = axios.create({
    withCredentials: true,
});
baseUrlMiddleware(instance);
export const updateToken = async () => axios.post("/api/profile/refresh/", {}, { withCredentials: true, headers: { "X-CSRFToken": await getCSRFToken() } });
instance.interceptors.request.use((config) => {
    const natuid = Cookies.get("natuid");
    if (natuid) {
        config.headers.Authorization = `Bearer ${Cookies.get("natuid")}`;
    }
    return config;
});
instance.interceptors.response.use(async (originalResponse) => {
    const originalRequest = originalResponse.config;
    if (isAuthResponseError(originalResponse === null || originalResponse === void 0 ? void 0 : originalResponse.data) && originalRequest) {
        // @ts-ignore
        originalRequest._isRetry = true;
        try {
            const response = await updateToken();
            if (isAuthResponseError(response.data)) {
                store.dispatch(loadUserData(true));
            }
            else {
                return instance.request(originalRequest);
            }
        }
        catch (_a) {
            // pass
        }
    }
    if (isResponseError(originalResponse)) {
        throw originalResponse.data;
    }
    return originalResponse;
});
export default instance;
